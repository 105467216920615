import React from 'react'

const CheckCircle = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='26.122' height='25.53' viewBox='0 0 26.122 25.53'>
			<g id='Icon_feather-check-circle' data-name='Icon feather-check-circle' transform='translate(1.502 1.514)'>
				<path
					id='Path_24'
					data-name='Path 24'
					d='M25.5,13.212v1.035A11.249,11.249,0,1,1,18.828,3.965'
					transform='translate(-3 -2.991)'
					fill='none'
					stroke='#727272'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='3'
				/>
				<path
					id='Path_25'
					data-name='Path 25'
					d='M28.124,6,16.875,17.261,13.5,13.886'
					transform='translate(-5.625 -3.743)'
					fill='none'
					stroke='#727272'
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='3'
				/>
			</g>
		</svg>
	)
}

export default CheckCircle
