import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * @param email - {firstName, lastName, emailAddress, gender, role, company}
 * @param props
 * @param signal
 */
export const addUserDeferFn: DeferFn<any> = async ([values], props, { signal }) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const response = await fetch(`${checkbeeAPIBase}/user`, {
		signal,
		method: 'POST',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(values),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to create user!')
		throw new Error(err)
	}
}
