import { CheckOutlined } from '@ant-design/icons'
import { Badge, Button, Popover, Typography } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ListIcon from '../../../../Assets/SvgIcons/ListIcon'
import { applyFilter } from '../../taskSlice'

const StatusRow = ({ title, selected, onClick }: { title: string; selected: boolean; onClick: Function }) => {
	return (
		<div onClick={() => onClick()} className='subcategory-filter-row'>
			<Typography className='subcategory-filter-row-title'>{title}</Typography>
			{selected ? <CheckOutlined style={{ color: '#F7C456' }} /> : null}
		</div>
	)
}

const StatusFilter = () => {
	const dispatch = useDispatch()
	const [selected, setSelected] = useState<string[]>(['CREATED', 'INPROGRESS'])
	const [visible, setVisible] = useState(false)

	let data = ['CREATED', 'INPROGRESS', 'COMPLETED']
	return (
		<>
			<Popover
				placement='bottomRight'
				open={visible}
				onOpenChange={(newVisible) => {
					setVisible(newVisible)
				}}
				content={
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 200 }}>
						<Typography className='filter-title'>CHOOSE STATUS</Typography>
						<div style={{ width: '116%', maxHeight: 400, overflow: 'scroll' }}>
							{data.map((status) => (
								<StatusRow
									key={status}
									onClick={() => {
										let newResult = selected
										if (selected.some((e) => e === status)) {
											newResult = newResult.filter((e) => e !== status)
										} else {
											newResult = [...selected, status]
										}
										setSelected(newResult)
										dispatch(applyFilter({ statuses: newResult }))
									}}
									selected={selected.some((e) => e === status)}
									title={status}
								/>
							))}
						</div>
						<Button
							onClick={() => {
								setSelected(['CREATED', 'INPROGRESS'])
								setVisible(false)
								dispatch(applyFilter({ statuses: ['CREATED', 'INPROGRESS'] }))
							}}
							
							className='task-action-button'
							style={{ marginTop: 10 }}
							type='link'>
							Clear filter
						</Button>
					</div>
				}
				trigger='click'>
				<Button type='link' className='filter-button'>
					{ListIcon()}
				</Button>
			</Popover>
			<Badge className='tasks-filter-badge' size='small' count={selected.length} />
		</>
	)
}

export default StatusFilter
