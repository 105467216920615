import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { CheckList, CheckListCategory, CheckPoint, updateChecklistSorting } from '../checklistSlice'
import { SubCategory } from '../../category/categorySlice'
import { User } from '../../auth/authSlice'
import { EditState } from '../components/AddChecklist/types'

export const useChecklistState = () => {
  const dispatch = useDispatch()
  const checklists = useSelector<RootState, CheckList[]>((state) => state.checklist.checklists)
  
  const [editState, setEditState] = useState<EditState>({
    title: false,
    checkpoint: false,
    category: false,
  })
  
  const [syncLoading, setSyncLoading] = useState(false)
  const [selected, setSelected] = useState<CheckList | undefined>(checklists[0])
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number | undefined>(selected ? 0 : undefined)
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string | undefined>(
    (checklists[0]?.subcategory as SubCategory)?.id
  )
  const [selectedCheckPointIndex, setSelectedCheckPointIndex] = useState<number | undefined>(selected ? 0 : undefined)
  const [toDeleteCatsIds, setToDeleteCatsIds] = useState<string[]>([])
  const [toDeleteCheckpointsIds, setToDeleteCheckpointsIds] = useState<string[]>([])

  const onSortAreasEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (!selected) return
    
    let currentChecklistAreas = [...(selected?.categories ?? [])]
    const b = { ...currentChecklistAreas[oldIndex] }
    currentChecklistAreas.splice(oldIndex, 1)
    currentChecklistAreas.splice(newIndex, 0, b)

    setSelected({
      ...selected,
      categories: currentChecklistAreas.map((item, idx) => ({ ...item, sort: idx })),
    })
    setSelectedCategoryIndex(newIndex)
  }

  const onSortCheckpointsEnd = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (!selected) return
    
    let currentAreaCheckpoint = [...(selected?.categories[selectedCategoryIndex ?? 0].checkpoints ?? [])]
    const b = { ...currentAreaCheckpoint[oldIndex] }
    currentAreaCheckpoint.splice(oldIndex, 1)
    currentAreaCheckpoint.splice(newIndex, 0, b)

    setSelected({
      ...selected,
      categories: selected.categories.map((cat, index) =>
        index === selectedCategoryIndex
          ? {
              ...cat,
              checkpoints: currentAreaCheckpoint.map((item, idx) => ({
                ...item,
                isEdited: true,
                sort: idx,
              })),
            }
          : cat
      ),
    })
    setSelectedCheckPointIndex(newIndex)
    // dispatch(
    //   updateChecklistSorting({
    //     checklistId: selected.id || '',
    //     categoryIdx: selectedCategoryIndex ?? 0,
    //     checkpoints: currentAreaCheckpoint,
    //   })
    // )
  }

  const addNewArea = () => {
    setSelected((cl) =>
      cl
        ? {
            ...cl,
            categories: cl.categories.concat({
              name: 'New Area',
              id: 'new-area-' + (cl.categories ?? []).length,
              isNew: true,
              checkpoints: [],
              updatedBy: undefined,
              createdBy: undefined,
              sort: cl.categories.length,
            }),
          }
        : undefined
    )
    setSelectedCheckPointIndex(undefined)
    setSelectedCategoryIndex(selected?.categories.length)
  }

  const addNewCheckpoint = () => {
    setSelected((cl) =>
      cl
        ? {
            ...cl,
            categories: cl.categories.map((m, index) => {
              if (index === selectedCategoryIndex) {
                return {
                  ...m,
                  checkpoints: [
                    ...m.checkpoints,
                    {
                      id: 'new_checkpoint-' + (m.checkpoints ?? []).length,
                      title: 'new checkpoint',
                      description: 'checkpoint description',
                      assign: undefined,
                      createdBy: undefined,
                      imageUrls: [],
                      status: '',
                      task: undefined,
                      isEdited: true,
                      isNew: true,
                      sort: (m.checkpoints ?? []).length,
                    },
                  ],
                }
              }
              return m
            }),
          }
        : undefined
    )
    setSelectedCheckPointIndex(selected?.categories[+(selectedCategoryIndex as number)].checkpoints.length)
  }

  const getChanges = () => {
    if (selected && !selected.isNew) {
      const ni = checklists.findIndex((e) => e.id === selected.id)
      const newAssignees = (selected.assign as User[]) ?? []
      const oldAssignees = checklists[ni].assign as User[]
      const isAssigneesChanged =
        newAssignees.length !== (oldAssignees?.length ?? 0) ||
        !newAssignees.every((user) => oldAssignees?.some((oldUser) => oldUser.id === user.id))

      const { every, count } = JSON.parse(selected.repeatedInfo ?? '{}')
      const { every: oldEvery, count: oldCount } = JSON.parse(checklists[ni].repeatedInfo ?? '{}')

      return (
        selected.title !== checklists[ni].title ||
        isAssigneesChanged ||
        toDeleteCatsIds.length > 0 ||
        toDeleteCheckpointsIds.length > 0 ||
        every !== oldEvery ||
        count !== oldCount ||
        selected.nextPublishDate !== checklists[ni].nextPublishDate
      )
    }

    return selected?.categories?.some((cat) => cat.checkpoints.length > 0) ?? false
  }

  return {
    editState,
    setEditState,
    syncLoading,
    setSyncLoading,
    selected,
    setSelected,
    selectedCategoryIndex,
    setSelectedCategoryIndex,
    selectedSubCategoryId,
    setSelectedSubCategoryId,
    selectedCheckPointIndex,
    setSelectedCheckPointIndex,
    toDeleteCatsIds,
    setToDeleteCatsIds,
    toDeleteCheckpointsIds,
    setToDeleteCheckpointsIds,
    onSortAreasEnd,
    onSortCheckpointsEnd,
    addNewArea,
    addNewCheckpoint,
    getChanges,
  }
}
