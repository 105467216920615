import { useAsync } from 'react-async'
import { useState, useEffect } from 'react'
import { User } from '../../auth/authSlice'
import { fetchSubcategoryUsersDeferFn } from '../actions/fetchSubcategoryUsers'
import { SubCategory } from '../../category/categorySlice'

interface UseSubcategoryUsersProps {
  subcategoryId?: string
  onUsersLoaded?: (users: User[]) => void
}

export const useSubcategoryUsers = ({ subcategoryId, onUsersLoaded }: UseSubcategoryUsersProps) => {
  const [users, setUsers] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchUsersAsync = useAsync({
    deferFn: fetchSubcategoryUsersDeferFn,
    onResolve: (data: SubCategory) => {
      setUsers(data?.users ?? [])
      onUsersLoaded?.(data.users ?? [])
      setIsLoading(false)
    },
    onReject: () => {
      setUsers([])
      setIsLoading(false)
    },
  })

  useEffect(() => {
    if (subcategoryId) {
      setIsLoading(true)
      fetchUsersAsync.run(subcategoryId)
    } else {
      setUsers([])
    }
  }, [subcategoryId]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    users,
    isLoading,
    error: fetchUsersAsync.error,
  }
}
