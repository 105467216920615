import { useFormik } from 'formik'
import * as Yup from 'yup'
import { User } from '../../auth/authSlice'

const EditSchema = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  assign: Yup.array().optional(),
  repeatedValue: Yup.string().nullable(),
  count: Yup.number().optional().nullable(),
  dateTime: Yup.date().optional().nullable(),
  notificationEnabled: Yup.boolean().required(),
})

export interface ChecklistFormValues {
  title: string
  assign: User[]
  repeatedValue: string | null
  count: number | null
  dateTime: Date | null
  notificationEnabled: boolean
}

interface UseChecklistFormProps {
  initialValues: ChecklistFormValues
  onSubmit: (values: ChecklistFormValues) => void
}

export const useChecklistForm = ({ initialValues, onSubmit }: UseChecklistFormProps) => {
  const formik = useFormik<ChecklistFormValues>({
    validationSchema: EditSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit,
  })

  return formik
}
