/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Col, Form, Input, Row, Radio, Button, Select, Tooltip, Upload, Typography } from 'antd'
import type { DatePickerProps } from 'antd'

import {
	ExclamationOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
	MessageOutlined,
	UserOutlined,
	ApartmentOutlined,
	PlusOutlined,
	ReloadOutlined,
	FieldTimeOutlined,
} from '@ant-design/icons/lib'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { colors } from '../../../Assets/colors'
import { uploadImage } from '../actions/uploadImage'
import { TaskTemplates } from '../taskTemplatesSlice'
import { Category } from '../../category/categorySlice'
import { User } from '../../auth/authSlice'
import { VisibleItem } from '../../../Components/VisibleItem'
import CBDatePicker from '../../../Components/DatePicker'
import moment, { Moment } from 'moment'

interface Props {
	isEditingTaskTemplate: boolean
	isGettingCategories: boolean
	isGettingUsers: boolean
	isEditTaskTemplateFulfilled: boolean
	isGettingTasks: boolean
	editTaskTemplate: (values: TaskTemplateForm) => void
	deleteTaskTemplate: (id: string) => void
	isDeletingTaskTemplate: boolean
	taskTemplate: TaskTemplates
	categories: Category[]
	availableAssignees: User[]
}

const StyledForm = styled(Form)`
	margin-top: 10px;
	.ant-form-item-label > label {
		height: 38px;
	}
`

export type Status = 'COMPLETED' | 'CREATED' | 'INPROGRESS'

export type TaskTemplateForm = {
	title?: string
	description?: string
	priority?: number
	category?: string // id
	subCategory?: string // id
	assign?: string // id
	status?: Status
	item?: string
	imageUrls?: string[]
}

const TaskFormSchema = Yup.object().shape({
	title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	description: Yup.string().nullable(),
	priority: Yup.number().required('Required'),
	category: Yup.string().required('Required'),
	subCategory: Yup.string().required('Required'),
	status: Yup.string().oneOf(['CREATED', 'INPROGRESS', 'COMPLETED']),
	count: Yup.number().required('Required').min(1),
})

type TaskFields =
	| 'title'
	| 'description'
	| 'priority'
	| 'category'
	| 'categoryId'
	| 'subCategory'
	| 'subCategoryId'
	| 'assign'
	| 'nextPublishDate'
	| 'repeatedInfo'

const REPEATED_EVERY_OPTIONS = [
	{ key: 'DAILY', value: 'Daily' },
	{ key: 'WEEKLY', value: 'Weekly' },
	{ key: 'MONTHLY', value: 'Monthly' },
	{ key: 'YEARLY', value: 'Yearly' },
	{ key: 'AMOUNT_DAYS', value: 'Amount Of Days' },
	{ key: 'AMOUNT_WEEKS', value: 'Amount Of Weeks' },
	{ key: 'AMOUNT_MONTHS', value: 'Amount Of Months' },
]
export const EditTaskTemplates: React.FC<Props> = ({
	taskTemplate,
	availableAssignees,
	isGettingUsers,
	isEditingTaskTemplate,
	isGettingCategories,
	categories,
	editTaskTemplate,
	deleteTaskTemplate,
	isDeletingTaskTemplate,
}) => {
	const repeatedInfoData = JSON.parse(taskTemplate.repeatedInfo)
	const getCategoryIndex = (subCatId: string) =>
		categories.findIndex((cat) => cat.subCategories.some((subCat) => subCat.id === subCatId))
	const [selectedCatIndex, setSelectedCatIndex] = React.useState(getCategoryIndex(taskTemplate.subCategory.id))
	const [imageUploading, setImageUploading] = React.useState(false)

	const [images, setImages] = React.useState<any[]>(
		taskTemplate.imageUrls?.map((e, i) => ({ uid: i, url: e, status: 'done' })) ?? []
	)
	const [repeatedValue, setSelectedRepeatedValue] = React.useState(repeatedInfoData.every)
	const [dateTime, setDateTime] = React.useState<Moment | null>(moment(taskTemplate.nextPublishDate))
	const handleChangeInput = (value: any) => {
		setSelectedRepeatedValue(value)
	}

	const onChange: DatePickerProps['onChange'] = (value) => {
		setDateTime(value as Moment)
	}

	const initialValues: TaskTemplateForm & { count: string } = {
		title: taskTemplate.title,
		description: taskTemplate.description,
		priority: taskTemplate.priority,
		subCategory: taskTemplate.subCategoryId ?? '',
		category: taskTemplate.categoryId ?? '',
		assign: taskTemplate.assign?.id ?? '',
		count: repeatedInfoData.count,
	}

	const [form] = Form.useForm()
	const formState = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: TaskFormSchema,
		onSubmit: (values) => {
			let requestData = {
				id: taskTemplate.id,
				imageUrls: images.map((image) => image.url),
			}
			const addIfEdited = (fieldName: TaskFields, newValue: string | number | undefined | null | Date) =>
				taskTemplate[fieldName] !== newValue ? { [fieldName]: newValue } : {}

			requestData = { ...requestData, ...addIfEdited('title', values.title) }
			requestData = { ...requestData, ...addIfEdited('description', values.description) }
			requestData = { ...requestData, ...addIfEdited('priority', values.priority) }
			requestData = { ...requestData, ...addIfEdited('category', values.category) }
			requestData = { ...requestData, ...addIfEdited('subCategory', values.subCategory) }
			requestData = { ...requestData, ...addIfEdited('assign', values.assign ? values.assign : null) }
			requestData = { ...requestData, ...addIfEdited('nextPublishDate', dateTime?.toDate()) }
			requestData = {
				...requestData,
				...addIfEdited('repeatedInfo', JSON.stringify({ every: repeatedValue, count: parseInt(values.count) })),
			}

			editTaskTemplate(requestData)
		},
	})

	const disabled = !!taskTemplate.archived
	return (
		<Row style={{ pointerEvents: disabled ? 'none' : 'unset' }} gutter={32}>
			{/* TASK FORM */}
			<Col span={14}>
				<VisibleItem visible={disabled}>
					<Typography.Text style={{ marginLeft: 20 }} type={'danger'}>
						Task is archived, You can't change it anymore
					</Typography.Text>
				</VisibleItem>
				<StyledForm form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 20 }}>
					{/* title */}
					<Form.Item
						label={<FileTextOutlined style={styles.iconLabel} />}
						name='title'
						validateStatus={formState.touched.title && formState.errors.title ? 'error' : ''}>
						<Input
							size='large'
							placeholder='TITLE'
							name='title'
							defaultValue={formState.initialValues.title}
							value={formState.values.title}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						label={<MessageOutlined style={styles.iconLabel} />}
						name='description'
						validateStatus={formState.touched.description && formState.errors.description ? 'error' : ''}>
						<Input.TextArea
							autoSize={{ minRows: 4, maxRows: 8 }}
							placeholder='DESCRIPTION'
							name='description'
							defaultValue={formState.initialValues.description}
							value={formState.values.description}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>

					{/* category */}
					<Form.Item
						label={<ApartmentOutlined style={styles.iconLabel} />}
						name='category'
						hasFeedback
						validateStatus={formState.errors.category ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='CATEGORY' />
							<Select
								style={{ flex: '1 0 65%' }}
								size='large'
								loading={isGettingCategories}
								disabled={isGettingCategories}
								value={formState.values.category}
								onChange={(value) => {
									formState.setFieldValue('category', value)
									const index = categories.findIndex((cat) => cat.id === value)
									setSelectedCatIndex(index)
									formState.setFieldValue('subCategory', categories[index].subCategories[0].id)
									formState.setFieldValue('assign', '')
									formState.setFieldValue('item', '')
								}}>
								{categories.length &&
									categories
										.filter((e) => e.subCategories.length)
										.map((cat) => {
											return (
												<Select.Option key={cat.id} value={cat.id}>
													{cat.name}
												</Select.Option>
											)
										})}
							</Select>
						</Input.Group>
					</Form.Item>

					{/* subcategory */}
					<Form.Item
						label={<ApartmentOutlined style={styles.iconLabel} />}
						name='category'
						hasFeedback
						validateStatus={formState.errors.category ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='SUBCATEGORY' />
							<Select
								style={{ flex: '1 0 65%' }}
								size='large'
								loading={isGettingCategories}
								disabled={isGettingCategories}
								value={formState.values.subCategory}
								onChange={(value) => {
									formState.setFieldValue('subCategory', value)
									formState.setFieldValue('item', '')
									formState.setFieldValue('assign', '')
								}}>
								{categories.length &&
									categories[selectedCatIndex].subCategories.map((subcat) => {
										return (
											<Select.Option key={subcat.id} value={subcat.id}>
												{subcat.name}
											</Select.Option>
										)
									})}
							</Select>
						</Input.Group>
					</Form.Item>

					{/* assignee */}
					<Form.Item
						hasFeedback
						label={<UserOutlined style={styles.iconLabel} />}
						validateStatus={formState.errors.assign ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='ASSIGN TO' />
							<Select
								style={{ flex: '1 0 65%' }}
								size='large'
								loading={isGettingUsers}
								disabled={isGettingUsers}
								value={formState.values.assign}
								onChange={(value) => formState.setFieldValue('assign', value)}>
								<Select.Option value={''}>{'No one'}</Select.Option>
								{availableAssignees.length &&
									availableAssignees
										.filter((user) => user.subCategories.find((subCat) => subCat.id === formState.values.subCategory))
										.map((user) => (
											<Select.Option key={user.id} value={user.id}>
												{user.firstName} {user.lastName}
											</Select.Option>
										))}
							</Select>
						</Input.Group>
					</Form.Item>
					{/* priority */}
					<Form.Item
						hasFeedback
						label={<ExclamationOutlined style={styles.iconLabel} />}
						validateStatus={formState.errors.priority ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='PRIORITY' />
							<Radio.Group
								size='large'
								name='priority'
								buttonStyle='solid'
								defaultValue='medium'
								style={{ marginRight: 12, flex: '1 0 60%' }}
								value={formState.values.priority}
								onChange={(e) => formState.setFieldValue('priority', e.target.value)}>
								<Radio.Button style={styles.radioButton} value={1}>
									!
								</Radio.Button>
								<Radio.Button style={styles.radioButton} value={2}>
									!!
								</Radio.Button>
								<Radio.Button style={styles.radioButton} value={3}>
									!!!
								</Radio.Button>
							</Radio.Group>
							<Tooltip title='Set the priority of this task' placement='right'>
								<InfoCircleOutlined />
							</Tooltip>
						</Input.Group>
					</Form.Item>

					{/* Repeated Every */}
					<Form.Item
						label={<ReloadOutlined style={styles.iconLabel} />}
						name='repeated'
						hasFeedback
						validateStatus={formState.errors.count ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='REPEATED EVERY' />
							<div style={{ display: 'flex', flex: '1 0 65%' }}>
								<Select
									size='large'
									style={{ width: '100%', borderRadius: 0 }}
									defaultValue={repeatedInfoData.every}
									onChange={(value) => {
										handleChangeInput(value)
										if (value === 'DAILY' || value === 'WEEKLY' || value === 'MONTHLY' || value === 'YEARLY') {
											formState.setFieldValue('count', 1)
										}
									}}>
									{REPEATED_EVERY_OPTIONS.map((item) => {
										return (
											<Select.Option key={item.key} value={item.key}>
												{item.value}
											</Select.Option>
										)
									})}
								</Select>

								{repeatedInfoData.count ||
								repeatedValue === 'AMOUNT_DAYS' ||
								repeatedValue === 'AMOUNT_WEEKS' ||
								repeatedValue === 'AMOUNT_MONTHS' ? (
									<Input
										size='large'
										style={{ width: '28%', borderLeft: 0 }}
										name='count'
										onChange={formState.handleChange}
										onBlur={formState.handleBlur}
										placeholder='count'
										value={formState.values.count}
										disabled={
											repeatedValue === 'DAILY' ||
											repeatedValue === 'WEEKLY' ||
											repeatedValue === 'MONTHLY' ||
											repeatedValue === 'YEARLY'
										}
									/>
								) : null}
							</div>
						</Input.Group>
					</Form.Item>

					{/* start from */}
					<Form.Item label={<FieldTimeOutlined style={styles.iconLabel} />} name='repeated' hasFeedback>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='START FROM' />
							<CBDatePicker
								style={{ flex: '1 0 65%' }}
								size='large'
								disabledDate={(current) => current.isBefore(moment())}
								showTime={{ format: 'hh:mm A', use12Hours: true }}
								use12Hours
								showSecond={false}
								minuteStep={30}
								onChange={onChange}
								allowClear={false}
								format={'DD.MM.YYYY h:mm A'}
							/>
						</Input.Group>
					</Form.Item>
				</StyledForm>
			</Col>
			<Col span={10}>
				<Upload
					onRemove={(file) => {
						setImages(images.filter((image) => image.uid !== file.uid))
					}}
					customRequest={async ({ file }) => {
						setImageUploading(true)
						const response = await uploadImage(file as File)
						setImages([...images, { uid: new Date().getTime().toString(), url: response.data, status: 'done' }])
						setImageUploading(false)
					}}
					listType='picture-card'
					fileList={images}>
					{images.length >= 4 ? null : (
						<div>
							<PlusOutlined />
							<div style={{ marginTop: 8 }}>Upload</div>
						</div>
					)}
				</Upload>
			</Col>

			<VisibleItem visible={!disabled}>
				<div className='task-edit-actions-container'>
					<Button
						className='task-action-button'
						type='primary'
						style={{ marginRight: 20 }}
						disabled={isEditingTaskTemplate || imageUploading || !formState.isValid}
						loading={isEditingTaskTemplate}
						onClick={() => formState.handleSubmit()}>
						SAVE
					</Button>

					<Button
						className='task-action-button'
						type='primary'
						danger
						disabled={isDeletingTaskTemplate || imageUploading}
						loading={isDeletingTaskTemplate}
						onClick={() => deleteTaskTemplate(taskTemplate.id)}>
						ARCHIVE
					</Button>
				</div>
			</VisibleItem>
		</Row>
	)
}

const styles = {
	iconLabel: { fontSize: 24, color: colors.primaryColor },
	radioButton: { textAlign: 'center' as const },
}
