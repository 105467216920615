import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider } from 'antd';
import { persistor, store } from './store'
import * as serviceWorker from './serviceWorker'
import './index.css'

import { AppContainer } from './Modules/app/containers/AppContainer'

const theme = {
  token: {
    colorPrimary: '#F7C456',
		lightgray: 'lightgray',
  },
  components: {
    Form: {
      itemMarginBottom: 17,
      labelColor: '#707070',
      verticalLabelPadding: '0 0 4px',
      colon: false,
    },
    Layout: {
      siderBg: '#F7C456',
      bodyBg: '#fff',
    },
    Menu: {
      darkBg: '#F7C456',
      itemActiveBg: '#F7C456',
      itemBorderWidth: 0,
    },
    Table: {
      headerBg: '#F7C456',
    }
  }
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider theme={theme}>
        <Router>
          <AppContainer />
        </Router>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
