import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * editTaskDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const editTaskAsync = useAsync({ deferFn: editTaskDeferFn })
 * const initiateEditTask = (arg: any, anotherArg: any) => {
 * 		editTaskAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: editTaskAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param values - {title, description, priority, category, subCategory, assign}
 * @param props
 * @param signal
 */
export const editTaskDeferFn: DeferFn<any> = async ([values], props, { signal }) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/task/${values.id}`, {
		signal,
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(values),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to edit task!')
		throw new Error(err)
	}
}
