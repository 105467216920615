import { DeferFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * createMultipleSubcatsDeferFn
 * [react-async's deferFn - manually invoked]
 *
 * @example usage
 * const createMultipleSubcatsAsync = useAsync({ deferFn: createMultipleSubcatsDeferFn })
 * const initiateCreateMultipleSubcats = (arg: any, anotherArg: any) => {
 * 		createMultipleSubcatsAsync.run(arg, anotherArg)
 * }
 * useSideEffect({
 * 		asyncFn: createMultipleSubcatsAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param subcategories - { name }[]
 * @param props
 * @param signal
 */
export const createMultipleSubcatsDeferFn: DeferFn<any> = async ([subcategories], props, { signal }) => {
	const token = localStorage.getItem('chibi-tkn')
	const tenantKey = process.env.REACT_APP_TENANT_KEY

	const promises = subcategories.map((subcat: { name: string }) => {
		return new Promise(async (resolve, reject) => {
			const res = await fetch(`${checkbeeAPIBase}/subcategory`, {
				signal,
				method: 'POST',
				headers: {
					'Tenant-Key': `${tenantKey}`,
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(subcat),
			})

			const result = await res.json()

			if (res.ok && result.status === 'success') {
				resolve(result.data)
			} else {
				const err = handleError(result, 'Something went wrong!')
				reject(new Error(`Creating subcategory - ${err}`))
			}
		})
	})

	try {
		await Promise.all(promises)
	} catch (e) {
		throw e
	}
}
