import { DeferFn, PromiseFn } from 'react-async'

import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'
import { Category } from '../categorySlice'

export const updateCategory = async (category: Category, signal: AbortSignal) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')

	const response = await fetch(`${checkbeeAPIBase}/category/${category.id}`, {
		signal,
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify(category),
	})

	const result = await response.json()
	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to update category!')
		throw new Error(err)
	}
}

export const updateCategoryPromiseFn: PromiseFn<any> = async ({ data: { companyId, ...rest } }, { signal }) => {
	return await updateCategory({ company: companyId, ...rest }, signal)
}

export const updateCategoryDeferFn: DeferFn<any> = async ([{ companyId, ...rest }], props, { signal }) => {
	return await updateCategory({ company: companyId, ...rest }, signal)
}
