import React from 'react'
import { Row, Col, Form, Input, Select, Spin } from 'antd'
import { FormikProps } from 'formik'
import { User } from '../../../auth/authSlice'
import { SubCategory } from '../../../category/categorySlice'
import { Item } from '../../../item/itemSlice'
import { VisibleItem } from '../../../../Components/VisibleItem'
import { ChecklistFormValues } from '../../hooks/useChecklistForm'
import { useSubcategoryUsers } from '../../hooks/useSubcategoryUsers'

interface AssignmentSectionProps {
  formik: FormikProps<ChecklistFormValues>
  subcategories: SubCategory[]
  selectedSubCategoryId?: string
  onSubcategoryChange: (value: string) => void
  itemsEnabled: boolean
  items: Item[]
  itemId: string
  onItemChange: (value: string) => void
}

export const AssignmentSection: React.FC<AssignmentSectionProps> = ({
  formik,
  subcategories,
  selectedSubCategoryId,
  onSubcategoryChange,
  itemsEnabled,
  items,
  itemId,
  onItemChange,
}) => {
  const { users, isLoading } = useSubcategoryUsers({
    subcategoryId: selectedSubCategoryId,
    onUsersLoaded: (loadedUsers) => {
    },
  })

  return (
    <Row gutter={24} style={{ marginTop: 30 }}>
      <Col span={12}>
        {/* subcategory */}
        <Form.Item hasFeedback style={{ width: '100%' }}>
          <Input.Group compact style={{ display: 'flex' }}>
            <Input disabled size="large" defaultValue="SUBCATEGORY" />
            <Select
              style={{ flex: '1 0 66%' }}
              size="large"
              value={selectedSubCategoryId || 'unselected'}
              onChange={(value: string) => {
                onSubcategoryChange(value)
                formik.setFieldValue('assign', [])
              }}>
              {(subcategories ?? []).map((sub) => (
                <Select.Option key={sub.id} value={sub.id}>
                  {sub.name}
                </Select.Option>
              ))}
            </Select>
          </Input.Group>
        </Form.Item>
        <VisibleItem visible={itemsEnabled}>
          <Form.Item hasFeedback style={{ width: '100%' }}>
            <Input.Group compact style={{ display: 'flex' }}>
              <Input disabled size="large" defaultValue="ITEM" />
              <Select
                style={{ flex: '1 0 66%' }}
                size="large"
                value={itemId}
                onChange={onItemChange}>
                <Select.Option value="">Link an item(Optional)</Select.Option>
                {(items ?? [])
                  .filter((item) => item.subCategoryId === selectedSubCategoryId)
                  .map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Input.Group>
          </Form.Item>
        </VisibleItem>
      </Col>

      <Col span={12}>
        {/* assign */}
        <Row>
          <Form.Item
            hasFeedback
            validateStatus={formik.touched.assign && formik.errors.assign ? 'error' : ''}
            style={{ minWidth: '100%' }}>
            <Input.Group compact style={{ display: 'flex' }}>
              <Input disabled size="large" defaultValue="ASSIGNED TO" />
              <Select
                style={{ flex: '1 0 65%' }}
                size="large"
                mode="multiple"
                allowClear
                loading={isLoading}
                placeholder={isLoading ? 'Loading users...' : 'No assignee'}
                value={(formik.values.assign ?? []).map((e) => e.id)}
                onBlur={() => formik.setFieldTouched('assign', true)}
                onChange={(value) => {
                  if (value.length) {
                    const selectedUsers = value.map((id) => users.find((user) => user.id === id))
                    formik.setFieldValue('assign', selectedUsers.filter(Boolean))
                  } else {
                    formik.setFieldValue('assign', [])
                  }
                }}>
                {users.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.firstName + ' ' + user.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Input.Group>
          </Form.Item>
        </Row>
      </Col>
    </Row>
  )
}
