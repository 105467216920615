import React from 'react'
import { Button, Space } from 'antd'
import { CheckList } from '../../checklistSlice'

interface ActionButtonsProps {
  selected?: CheckList
  hasUnsavedChanges: boolean
  syncLoading: boolean
  hasFormErrors: boolean
  onSave: () => void
  onDelete: () => void
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  selected,
  hasUnsavedChanges,
  syncLoading,
  hasFormErrors,
  onSave,
  onDelete,
}) => {
  return (
    <Space size={8} style={{ marginTop: 32, float: 'right' }}>
      <Button
        disabled={!hasUnsavedChanges || syncLoading || hasFormErrors}
        size="large"
        type="primary"
        loading={syncLoading}
        onClick={onSave}>
        SAVE CHECKLIST
      </Button>
      <Button
        disabled={!selected || syncLoading}
        size="large"
        onClick={onDelete}>
        DELETE
      </Button>
    </Space>
  )
}
