import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

/**
 * A wrapper component that redirects to the login screen if not authenticated.
 */
export const PrivateRoute: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	const isAuthenticated = useSelector<RootState, boolean>(({ auth }) => auth.isAuthenticated)
	const location = useLocation()

	if (!isAuthenticated) {
		return <Navigate to="/login" state={{ from: location }} replace />
	}

	return children || <Outlet />
}
