import React, { useRef, useState, useEffect } from 'react'
import { Button, Spin, Typography } from 'antd'
import './styles.css'
import { User } from '../../../auth/authSlice'
import moment from 'moment'
import { LoadingOutlined } from '@ant-design/icons'
import { VisibleItem } from '../../../../Components/VisibleItem'

export interface TaskComment {
	createdAt: string
	user: User
	text: string
}

interface Props {
	comments: TaskComment[]
	tasksLoading: boolean
	addLoading: boolean
	addComment: (text: string) => void
}

const CommentsSection = ({ comments, tasksLoading, addLoading, addComment }: Props) => {
	const [text, setText] = useState('')
	const scroll = useRef(null)
	const input = useRef(null)

	useEffect(() => {
		;(scroll?.current as any)?.scroll?.({
			top: 10000,
			behavior: 'smooth',
		})
	}, [comments.length])

	const postDisabled = text.length < 2 || tasksLoading || addLoading
	return (
		<div className='task-comments'>
			<div>
				<Typography.Title level={4}>Comments</Typography.Title>
				<VisibleItem visible={!tasksLoading && !comments.length}>
					<div className='empty-list'>This task doesn't have any comments yet</div>
				</VisibleItem>
				<VisibleItem visible={tasksLoading}>
					<div className='loader'>
						<Spin size='large' indicator={<LoadingOutlined spin />} />
					</div>
				</VisibleItem>
				<div ref={scroll} className='comments'>
					{comments.map((comment) => (
						<div className='comment'>
							{moment(comment.createdAt).format('DD.MM.YYYY HH:mm')}{' '}
							<strong className='user-name'>{comment.user?.firstName + ' ' + comment.user?.lastName}</strong>
							<strong className='comment-message'>{comment.text}</strong>
						</div>
					))}
				</div>
			</div>
			<div className='input-container'>
				<span
					ref={input}
					onInput={(e) => setText(e.currentTarget.textContent ?? '')}
					className='textarea'
					contentEditable
				/>
				<Button
					loading={addLoading}
					onClick={() => {
						addComment(text)
						setText('')
						;(input?.current as any)!.textContent = ''
					}}
					disabled={postDisabled}
					type='primary'
					className='post-btn'>
					Post
				</Button>
			</div>
		</div>
	)
}

export default CommentsSection
