import React from 'react'
import { Card, Row, Col, Input, DatePicker, Select, Switch, Form } from 'antd'
import moment from 'moment'
import { FormikProps } from 'formik'
import { ChecklistFormValues } from '../../hooks/useChecklistForm'

const REPEATED_EVERY_OPTIONS = [
  { key: 'DAILY', value: 'Daily' },
  { key: 'WEEKLY', value: 'Weekly' },
  { key: 'MONTHLY', value: 'Monthly' },
  { key: 'YEARLY', value: 'Yearly' },
]

interface NotificationSettingsProps {
  formik: FormikProps<ChecklistFormValues>
}

export const NotificationSettings: React.FC<NotificationSettingsProps> = ({ formik }) => {
  return (
    <Card
      headStyle={{ paddingTop: 5, paddingBottom: 5 }}
      bodyStyle={{
        paddingBottom: 5,
        paddingTop: 25,
        display: formik.values.notificationEnabled ? 'block' : 'none',
      }}
      title="Is Notifications Enabled?"
      extra={
        <Switch
          defaultChecked={formik.values.notificationEnabled}
          onChange={(checked) => {
            formik.setFieldValue('repeatedValue', REPEATED_EVERY_OPTIONS[0].key)
            formik.setFieldValue('dateTime', new Date())
            formik.setFieldValue('notificationEnabled', checked)
            formik.setFieldValue('count', 1)

            if (!checked) {
              formik.setFieldValue('repeatedValue', null)
              formik.setFieldValue('dateTime', null)
              formik.setFieldValue('count', null)
            }
          }}
          checked={formik.values.notificationEnabled}
        />
      }
      style={{ width: '100%', marginTop: 10 }}>
      {formik.values.notificationEnabled && (
        <Row gutter={24}>
          <Col span={12}>
            <Row>
              <Form.Item hasFeedback style={{ minWidth: '100%' }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  <Input disabled size="large" defaultValue="START FROM" />
                  <DatePicker
                    style={{ flex: '1 0 65%', paddingTop: 8 }}
                    size="large"
                    disabledDate={(current) => current.isBefore(moment().toDate())}
                    showTime={{ format: 'hh:mm A', use12Hours: true }}
                    use12Hours
                    showSecond={false}
                    minuteStep={30}
                    allowClear={false}
                    format="DD.MM.YYYY h:mm A"
                    name="dateTime"
                    value={formik.values.dateTime ? moment(formik.values.dateTime) : null}
                    onChange={(date) => formik.setFieldValue('dateTime', date?.toDate() || null)}
                  />
                </Input.Group>
              </Form.Item>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Form.Item hasFeedback style={{ minWidth: '100%' }}>
                <Input.Group compact style={{ display: 'flex' }}>
                  <Input disabled size="large" defaultValue="REPEATED EVERY" />
                  <div style={{ display: 'flex', flex: '1 0 56%' }}>
                    <Select
                      value={formik.values.repeatedValue}
                      style={{ width: '100%', borderRadius: 0 }}
                      size="large"
                      defaultValue={REPEATED_EVERY_OPTIONS[0].key}
                      onChange={(value) => formik.setFieldValue('repeatedValue', value)}>
                      {REPEATED_EVERY_OPTIONS.map((item) => (
                        <Select.Option key={item.key} value={item.key}>
                          {item.value}
                        </Select.Option>
                      ))}
                    </Select>

                    <Input
                      size="large"
                      style={{ width: '37%', borderLeft: 0 }}
                      defaultValue="1"
                      name="count"
                      onChange={(e) => {
                        const value = parseInt(e.target.value)
                        formik.setFieldValue('count', isNaN(value) ? null : value)
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="count"
                      value={formik.values.count?.toString() || ''}
                    />
                  </div>
                </Input.Group>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  )
}
