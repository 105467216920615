import React, { useState } from 'react'
import { Typography, Row, Col } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { CheckList } from '../checklistSlice'
import { User } from '../../auth/authSlice'
import { Item } from '../../item/itemSlice'
import { CheckListCategory } from '../checklistSlice'
import { Category } from '../../category/categorySlice'
import { CheckbeeMenu } from '../../../Components/CheckbeeMenu'
import { CheckbeeMenuContent } from '../../../Components/CheckbeeMenuContent'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { CheckbeeDashboardContent } from '../../../Components/containers.styled'
import { QuickEditInput } from '../../../Components/QuickEditInput/QuickEditInput'
import styled from 'styled-components'
import { useChecklistForm } from '../hooks/useChecklistForm'
import { useChecklistState } from '../hooks/useChecklistState'
import { useChecklistAsync } from '../hooks/useChecklistAsync'
import {
  ChecklistHeader,
  AreasSection,
  CheckpointsSection,
  DetailsSection,
  NotificationSettings,
  AssignmentSection,
  ActionButtons,
} from './AddChecklist/index'
import { VisibleItem } from '../../../Components/VisibleItem'

interface Props {
  loggedInUser: User
  checklistCategories: CheckListCategory[]
  categories: Category[]
  receiveCheckList: (params: { checklist: CheckList[] }) => void
  receiveCategories: (params: { categories: Category[] }) => void
}

interface MenuRowProps {
  key: string
  content: string
}

export const AddChecklist: React.FC<Props> = ({
  loggedInUser,
  checklistCategories,
  categories,
  receiveCheckList,
  receiveCategories,
}) => {
  const checklists = useSelector<RootState, CheckList[]>((state) => state.checklist.checklists)
  const items: Item[] = useSelector<RootState, Item[]>((state) => state.item.list)
  const itemsEnabled = useSelector<RootState, boolean>((state) => state.auth.user.company.itemsEnabled)
  const [itemId, setItemId] = useState<string>(checklists[0]?.itemId ?? '')
  const [isLoadingDetails, setIsLoadingDetails] = useState(false)

  const {
    editState,
    setEditState,
    syncLoading,
    setSyncLoading,
    selected,
    setSelected,
    selectedCategoryIndex,
    setSelectedCategoryIndex,
    selectedSubCategoryId,
    setSelectedSubCategoryId,
    selectedCheckPointIndex,
    setSelectedCheckPointIndex,
    toDeleteCatsIds,
    setToDeleteCatsIds,
    toDeleteCheckpointsIds,
    setToDeleteCheckpointsIds,
    onSortAreasEnd,
    onSortCheckpointsEnd,
    addNewArea,
    addNewCheckpoint,
    getChanges,
  } = useChecklistState()

  const formik = useChecklistForm({
    initialValues: {
      title: selected ? selected.title : '',
      assign: selected ? (selected.assign as User[]) : [],
      count: JSON.parse(selected?.repeatedInfo ?? '{}').count ?? null,
      repeatedValue: JSON.parse(selected?.repeatedInfo ?? '{}').every ?? null,
      dateTime: selected?.nextPublishDate ? new Date(selected.nextPublishDate) : null,
      notificationEnabled: !!(selected ? selected.repeatedInfo && selected.nextPublishDate : false),
    },
    onSubmit: (values) => {
      setSyncLoading(true)
      setEditState({ title: false, checkpoint: false, category: false })
      const request: CheckList = {
        id: selected?.isNew ? '' : selected?.id,
        title: selected?.title !== values.title ? values.title : selected!.title,
        subcategory: selectedSubCategoryId as string,
        assign: values.assign.map((e: User) => e.id),
        repeatedInfo: values.notificationEnabled
          ? JSON.stringify({ every: values.repeatedValue, count: values.count ?? 1 })
          : null,
        nextPublishDate: values.notificationEnabled ? values.dateTime!.toISOString() : null,
        categories: selected?.categories.map((e, index) => ({
          id: e.isNew ? '' : e.id,
          name: e.isNew ? e.name : e.name !== checklistCategories[index]?.name ? e.name : '',
          checkpoints: e.checkpoints.map((m) => ({
            id: m.isNew ? '' : m.id,
            title: m.isNew ? m.title : m.isEdited ? m.title : '',
            description: m.description || '',
            status: '',
            sort: m.sort,
          })),
          sort: e.sort,
        })) ?? [],
      }

      if (!request.assign?.length) delete request.assign
      if (itemId) request.itemId = itemId

      createCheckListAsync.run(request)
    },
  })

  const {
    fetchChecklistAsync,
    fetchChecklistDetailAsync,
    createCheckListAsync,
    deleteCheckListAsync,
    deleteChecklistCategory,
    deleteChecklistCheckpoint,
    refreshViews,
  } = useChecklistAsync({
    onFetchSuccess: (checklist) => {
      receiveCheckList({ checklist })
      // Only update selected if it's a new checklist
      if (selected?.isNew) {
        const newChecklist = checklist[checklist.length - 1]
        setSelected(newChecklist)
        // Fetch full details for the new checklist
        fetchChecklistDetailAsync.run(newChecklist.id)
      }
    },
    onFetchDetailSuccess: (checklist) => {
      setSelected(checklist)
      setIsLoadingDetails(false)
      setSelectedCategoryIndex(0)
      setSelectedSubCategoryId((checklist.subcategory as any)?.id)
      setSelectedCheckPointIndex(0)
      setItemId(checklist.itemId ?? '')

      checklist.nextPublishDate
        ? formik.setFieldValue('dateTime', new Date(checklist.nextPublishDate))
        : formik.setFieldValue('dateTime', null)
      const { every, count } = JSON.parse(checklist.repeatedInfo ?? '{}')
      formik.setFieldValue('repeatedValue', every)
      formik.setFieldValue('count', count)
      formik.setFieldValue('notificationEnabled', !!(checklist.repeatedInfo && checklist.nextPublishDate))
    },
    onCreateSuccess: async () => {
      try {
        const deleteCatsPromises = toDeleteCatsIds.map(deleteChecklistCategory)
        await Promise.all(deleteCatsPromises)
        const deleteCheckpointPromises = toDeleteCheckpointsIds.map(deleteChecklistCheckpoint)
        await Promise.all(deleteCheckpointPromises)
        setToDeleteCatsIds([])
        setToDeleteCheckpointsIds([])
        setSyncLoading(false)
        // Refresh both list and detail views after successful creation
        await refreshViews()
      } catch (e) {
        setSyncLoading(false)
      }
    },
    onDeleteSuccess: async () => {
      // After deletion, refresh list and reset selection
      await fetchChecklistAsync.run()
      const firstChecklist = checklists[0]
      if (firstChecklist) {
        setSelected(firstChecklist)
        setSelectedCategoryIndex(0)
        setSelectedSubCategoryId((firstChecklist.subcategory as any)?.id)
        setSelectedCheckPointIndex(0)
        // Fetch details for the first checklist
        fetchChecklistDetailAsync.run(firstChecklist.id)
      } else {
        setSelected(undefined)
        setSelectedCategoryIndex(undefined)
        setSelectedSubCategoryId(undefined)
        setSelectedCheckPointIndex(undefined)
      }
    },
  })

  const addDuplicateFromCurrent = () => {
    if (!selected) return

    const newDup = { ...selected }
    delete newDup.id

    newDup.subcategory = (newDup.subcategory as any)?.id
    newDup.categories = ((newDup.categories as CheckListCategory[]) ?? [])?.map((cat) => ({
      name: cat.name,
      checkpoints: cat.checkpoints.map((checkpoint) => ({
        title: checkpoint.title,
        description: checkpoint.description,
      })),
    })) as CheckListCategory[]
    newDup.assign = ((newDup.assign as User[]) ?? [])?.map((user) => user.id)

    newDup.title = newDup.title + ' copy'

    createCheckListAsync.run(newDup)
  }

  const handleDeleteArea = (index: number) => {
    setSelected((cl) =>
      cl
        ? {
            ...cl,
            categories: cl.categories.filter((_, idx) => idx !== index),
          }
        : undefined
    )

    const removedCat = selected?.categories[index]
    if (removedCat && !removedCat.isNew) {
      setToDeleteCatsIds([...toDeleteCatsIds, removedCat.id ?? ''])
      setToDeleteCheckpointsIds([
        ...toDeleteCheckpointsIds,
        ...removedCat.checkpoints.filter((cp) => !cp.isNew).map((cp) => cp.id),
      ])
    }
  }

  const handleCloneArea = (index: number) => {
    const selectedArea = selected?.categories[index]
    setSelected((cl) =>
      cl
        ? {
            ...cl,
            categories: cl.categories.concat({
              name: selectedArea?.name + ' Copy',
              id: 'new-area-' + (cl.categories ?? []).length,
              isNew: true,
              checkpoints:
                selectedArea?.checkpoints.map((checkPoint, idx) => ({
                  id: 'new_checkpoint-' + checkPoint.id,
                  title: checkPoint?.title,
                  description: checkPoint?.description,
                  assign: undefined,
                  createdBy: undefined,
                  imageUrls: [],
                  status: '',
                  task: undefined,
                  isEdited: true,
                  isNew: true,
                  sort: idx,
                })) ?? [],
              updatedBy: undefined,
              createdBy: undefined,
              sort: cl.categories.length,
            }),
          }
        : undefined
    )
    setSelectedCheckPointIndex(selectedArea?.checkpoints.length ? 0 : undefined)
    setSelectedCategoryIndex(selected?.categories.length)
  }

  const handleDeleteCheckpoint = (index: number) => {
    setSelected(
      selected && {
        ...selected,
        categories:
          selected?.categories.map((cat, catIdx) => ({
            ...cat,
            checkpoints: cat.checkpoints.filter((_, idx) => idx !== index && selectedCategoryIndex === catIdx),
          })) ?? [],
      }
    )

    const removedCheckpoint = selected?.categories[selectedCategoryIndex ?? 0].checkpoints[index]
    if (removedCheckpoint && !removedCheckpoint.isNew) {
      setToDeleteCheckpointsIds([...toDeleteCheckpointsIds, removedCheckpoint.id])
    }
  }

  const handleCloneCheckpoint = () => {
    if (!selected || selectedCategoryIndex === undefined || selectedCheckPointIndex === undefined) return

    const length = selected.categories[selectedCategoryIndex].checkpoints.length ?? 0
    const checkpointToClone = selected.categories[selectedCategoryIndex].checkpoints[selectedCheckPointIndex]
    setSelected({
      ...selected,
      categories: selected.categories.map((cat, catIdx) => ({
        ...cat,
        checkpoints:
          catIdx === selectedCategoryIndex
            ? cat.checkpoints.concat([
                {
                  ...checkpointToClone,
                  id: 'new_checkpoint-' + cat.checkpoints.length,
                  title: checkpointToClone.title + ' copy',
                  isNew: true,
                  isEdited: true,
                  sort: cat.checkpoints.length,
                },
              ])
            : cat.checkpoints,
      })),
    })
    setSelectedCheckPointIndex(length)
  }

  const handleCheckpointTitleChange = (value: string) => {
    setSelected((cl) =>
      cl
        ? {
            ...cl,
            categories: cl.categories.map((m, index) => {
              if (index === selectedCategoryIndex) {
                return {
                  ...m,
                  checkpoints: m.checkpoints.map((n, j) => {
                    if (j === selectedCheckPointIndex) {
                      return { ...n, title: value, isEdited: true }
                    }
                    return n
                  }),
                }
              }
              return m
            }),
          }
        : undefined
    )
  }

  const handleDescriptionChange = (value: string) => {
    setSelected((cl) =>
      cl
        ? {
            ...cl,
            categories: cl.categories.map((m, index) => {
              if (index === selectedCategoryIndex) {
                return {
                  ...m,
                  checkpoints: m.checkpoints.map((n, j) => {
                    if (j === selectedCheckPointIndex) {
                      return { ...n, description: value, isEdited: true }
                    }
                    return n
                  }),
                }
              }
              return m
            }),
          }
        : undefined
    )
  }

  return (
		<LayoutWrapper>
			<Typography.Title level={4}>
				Manage your CHECKLISTS here. Create checklists, add checkpoints and assign to users.
			</Typography.Title>

			<CheckbeeDashboardContent>
				<CheckbeeMenu
					items={checklists
						.map((e) => ({ key: e.id as string, content: e.title }))
						.concat(selected?.isNew ? { key: selected.id as string, content: selected.title } : [])}
					title='CHECKLISTS'
					loading={fetchChecklistAsync.isPending || isLoadingDetails}
					onFooterClick={() => {
						setEditState({ ...editState, title: true })
						setSelected({
							createdBy: undefined,
							image: '',
							updatedBy: undefined,
							id: 'new-checklist',
							assign: [] as User[],
							title: 'New Checklist',
							categories: [],
							subcategory: selectedSubCategoryId as string,
							isNew: true,
							repeatedInfo: null,
							nextPublishDate: null,
						})
						setItemId('')
					}}
					renderRow={(row: MenuRowProps) => (
						<QuickEditInput
							value={row.content}
							onChange={(newName: string) => {
								formik.setFieldValue('title', newName)
								const newSelected = { ...selected, title: newName }
								setSelected(newSelected as CheckList)
								if (row.key !== 'new-checklist') {
									formik.handleSubmit()
									// After title edit, refresh both views
									refreshViews(row.key)
								}
							}}
						/>
					)}
					selectedKeys={[selected?.id ?? '']}
					onSelect={({ key }: { key: string }) => {
						const value = checklists.find((el) => el.id === key)
						if (value && !value.isNew) {
							setIsLoadingDetails(true)
							setEditState({ title: false, checkpoint: false, category: false })
							// Set minimal data first
							setSelected(value)
							// Fetch full details
							fetchChecklistDetailAsync.run(value.id)
						} else if (value) {
							setEditState({ title: false, checkpoint: false, category: false })
							setSelected(value)
							setSelectedCategoryIndex(0)
							setSelectedSubCategoryId((value.subcategory as any)?.id)
							setSelectedCheckPointIndex(0)
							setItemId(value.itemId ?? '')

							value.nextPublishDate
								? formik.setFieldValue('dateTime', new Date(value.nextPublishDate))
								: formik.setFieldValue('dateTime', null)
							const { every, count } = JSON.parse(value.repeatedInfo ?? '{}')
							formik.setFieldValue('repeatedValue', every)
							formik.setFieldValue('count', count)
							formik.setFieldValue('notificationEnabled', !!(value.repeatedInfo && value.nextPublishDate))
						}
					}}
				/>
				<VisibleItem visible={!!selected?.isNew || !!selected?.categories}>
					<Content>
						<Row gutter={24}>
							<Col span={22}>
								<CheckbeeMenuContent style={{ marginLeft: undefined }}>
									<ChecklistHeader
										editState={editState}
										setEditState={setEditState}
										formik={formik}
										selected={selected}
										selectedCategoryIndex={selectedCategoryIndex}
										hasUnsavedChanges={getChanges()}
										onDuplicate={addDuplicateFromCurrent}
									/>

									<Row gutter={18} style={{ marginTop: 24 }}>
										<AreasSection
											selected={selected}
											selectedCategoryIndex={selectedCategoryIndex}
											onSortEnd={onSortAreasEnd}
											onAreaClick={({ index }) => {
												setSelectedCheckPointIndex(0)
												setSelectedCategoryIndex(index)
												setEditState({ ...editState, checkpoint: false, category: false })
											}}
											onAddArea={addNewArea}
											onDeleteArea={handleDeleteArea}
											onCloneArea={handleCloneArea}
                      setSelected={setSelected}
										/>

										<CheckpointsSection
											selected={selected}
											selectedCategoryIndex={selectedCategoryIndex}
											selectedCheckPointIndex={selectedCheckPointIndex}
											onSortEnd={onSortCheckpointsEnd}
											onCheckpointClick={({ index }) => {
												setSelectedCheckPointIndex(index)
												setEditState({ ...editState, checkpoint: false })
											}}
											onAddCheckpoint={addNewCheckpoint}
											onDeleteCheckpoint={handleDeleteCheckpoint}
											onCloneCheckpoint={handleCloneCheckpoint}
                      setSelected={setSelected}
										/>

										<DetailsSection
											editState={editState}
											setEditState={setEditState}
											selected={selected}
											selectedCategoryIndex={selectedCategoryIndex}
											selectedCheckPointIndex={selectedCheckPointIndex}
											onCheckpointTitleChange={handleCheckpointTitleChange}
											onDescriptionChange={handleDescriptionChange}
										/>
									</Row>

									<AssignmentSection
										formik={formik}
										subcategories={categories.flatMap((cat) => cat.subCategories)}
										selectedSubCategoryId={selectedSubCategoryId}
										onSubcategoryChange={(value: string) => {
											setSelectedSubCategoryId(value)
										}}
										itemsEnabled={itemsEnabled}
										items={items}
										itemId={itemId}
										onItemChange={setItemId}
									/>

									<NotificationSettings formik={formik} />
								</CheckbeeMenuContent>

								<ActionButtons
									selected={selected}
									hasUnsavedChanges={getChanges()}
									syncLoading={syncLoading}
									hasFormErrors={Object.values(formik.errors).some((error) => !!error)}
									onSave={() => formik.handleSubmit()}
									onDelete={() => {
										if (selected && !selected?.isNew) {
											deleteCheckListAsync.run(selected.id as string)
											setSelectedSubCategoryId(undefined)
											setSelectedCategoryIndex(undefined)
										}
										setSelected(undefined)
									}}
								/>
							</Col>
						</Row>
					</Content>
				</VisibleItem>
			</CheckbeeDashboardContent>
		</LayoutWrapper>
	)
}

const Content = styled.div`
  width: 100%;
  margin-left: 32px;
`
