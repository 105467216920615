import React from 'react'
import { Avatar, Badge, Form, Input, Typography } from 'antd'
import { CheckbeeMenuContentHeader } from '../../../../Components/containers.styled'
import { VisibleItem } from '../../../../Components/VisibleItem'
import { Button } from 'antd'
import { FormikProps } from 'formik'
import { CheckList } from '../../checklistSlice'
import { ChecklistFormValues } from '../../hooks/useChecklistForm'
import { EditState } from './types'

interface ChecklistHeaderProps {
  editState: EditState
  setEditState: (state: EditState) => void
  formik: FormikProps<ChecklistFormValues>
  selected?: CheckList
  selectedCategoryIndex?: number
  hasUnsavedChanges: boolean
  onDuplicate: () => void
}

export const ChecklistHeader: React.FC<ChecklistHeaderProps> = ({
  editState,
  setEditState,
  formik,
  selected,
  selectedCategoryIndex,
  hasUnsavedChanges,
  onDuplicate,
}) => {
  return (
    <CheckbeeMenuContentHeader>
      <Avatar size="large" style={{ marginRight: 8 }} />
      {editState.title ? (
        <Form
          layout="inline"
          size="large"
          onBlur={() => setEditState({ ...editState, title: !editState.title })}>
          <Form.Item
            validateStatus={formik.errors.title ? 'error' : ''}
            style={{ marginRight: 8, maxWidth: 120 }}>
            <Input
              placeholder="Title"
              size="large"
              name="title"
              value={formik.values.title}
              onChange={(e) => {
                formik.handleChange(e)
                formik.setFieldValue('title', e.target.value)
              }}
            />
          </Form.Item>
        </Form>
      ) : (
        <div onClick={() => setEditState({ ...editState, title: !editState.title })}>
          <Typography.Title level={3} style={{ margin: '0 8px' }}>
            {formik.values.title}{' '}
          </Typography.Title>
        </div>
      )}
      {editState.category ? (
        <Form
          layout="inline"
          size="large"
          onBlur={() => setEditState({ ...editState, category: !editState.category })}>
          <Form.Item
            validateStatus={formik.errors?.title ? 'error' : ''}
            style={{ marginRight: 8, maxWidth: 120 }}>
            <Input
              placeholder="Area"
              size="large"
              disabled={selectedCategoryIndex === undefined}
              name="title"
              value={
                selectedCategoryIndex !== undefined && selected
                  ? selected.categories[selectedCategoryIndex]?.name
                  : ''
              }
              onChange={(e) => {
                if (selectedCategoryIndex !== undefined && selected) {
                  const newCategories = selected.categories.map((m, index) => {
                    if (index === selectedCategoryIndex) {
                      return {
                        ...m,
                        name: e.target.value,
                      }
                    }
                    return m
                  })
                  // Assuming you have a way to update the selected checklist
                  // setSelected({ ...selected, categories: newCategories })
                }
              }}
            />
          </Form.Item>
        </Form>
      ) : (
        <div onClick={() => setEditState({ ...editState, category: !editState.category })}>
          <Typography.Title level={3} style={{ margin: '0 8px' }}>
            {selectedCategoryIndex !== undefined && selected
              ? selected.categories[selectedCategoryIndex]?.name
              : ''}{' '}
          </Typography.Title>
        </div>
      )}
      <VisibleItem visible={!selected?.isNew}>
        <Button onClick={onDuplicate} style={{ position: 'absolute', right: 0 }}>
          Duplicate
        </Button>
      </VisibleItem>
    </CheckbeeMenuContentHeader>
  )
}
