import { DeferFn, PromiseFn } from 'react-async'
import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'
import { SubCategory } from '../../category/categorySlice'

/**
 * fetchSubcategoryUsers action
 * [generic] - can be reused in both defer and promise functions
 */
export const fetchSubcategoryUsers = async (subcategoryId: string, signal: AbortSignal): Promise<SubCategory> => {
  const token = localStorage.getItem('chibi-tkn')
  const tenantKey = process.env.REACT_APP_TENANT_KEY
  const response = await fetch(`${checkbeeAPIBase}/subcategory/${subcategoryId}`, {
    signal,
    method: 'GET',
    headers: {
      'Tenant-Key': `${tenantKey}`,
      'Authorization': `Bearer ${token}`,
    },
  })

  const result = await response.json()

  if (response.ok && result.status === 'success') {
    return result.data
  } else {
    const err = handleError(result, 'Failed to get subcategory users!')
    throw new Error(err)
  }
}

/**
 * fetchSubcategoryUsersPromiseFn
 * [react-async's promiseFn - automatic invocation]
 */
export const fetchSubcategoryUsersPromiseFn: PromiseFn<SubCategory> = async ({ subcategoryId }, { signal }) => {
  return await fetchSubcategoryUsers(subcategoryId, signal)
}

/**
 * fetchSubcategoryUsersDeferFn
 * [react-async's deferFn - manually invoked]
 */
export const fetchSubcategoryUsersDeferFn: DeferFn<SubCategory> = async ([subcategoryId], props, { signal }) => {
  return await fetchSubcategoryUsers(subcategoryId, signal)
}
