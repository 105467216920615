import React from 'react'
import { Col } from 'antd'
import { CheckbeeCascader } from '../../../../Components/CheckbeeCascader'
import { CheckList } from '../../checklistSlice'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons/lib'
import CloneIcon from '../../../../Assets/SvgIcons/CloneIcon'

interface CheckpointsSectionProps {
  selected?: CheckList
  selectedCategoryIndex?: number
  selectedCheckPointIndex?: number
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void
  onCheckpointClick: (value: { index: number }) => void
  onAddCheckpoint: () => void
  onDeleteCheckpoint: (index: number) => void
  onCloneCheckpoint: (index: number) => void
  setSelected: React.Dispatch<React.SetStateAction<CheckList | undefined>>
}

export const CheckpointsSection: React.FC<CheckpointsSectionProps> = ({
  selected,
  selectedCategoryIndex,
  selectedCheckPointIndex,
  onSortEnd,
  onCheckpointClick,
  onAddCheckpoint,
  onDeleteCheckpoint,
  onCloneCheckpoint,
  setSelected,
}) => {
  const renderActions = ({ index }: { index: number }) => {
    return (
      <div>
        <Button
          style={{ padding: 0, marginRight: 10 }}
          type="text"
          onClick={(e) => {
            e.stopPropagation()
            onDeleteCheckpoint(index)
          }}>
          <DeleteOutlined style={{ fontSize: 18 }} />
        </Button>
        <Button
          style={{ padding: 0 }}
          type="text"
          onClick={(e) => {
            e.stopPropagation()
            onCloneCheckpoint(index)
          }}>
          <CloneIcon />
        </Button>
      </div>
    )
  }

  return (
    <Col span={7}>
      <CheckbeeCascader
        onSortEnd={onSortEnd}
        allowInlineEditing={true}
        selectedItem={
          selected?.categories[selectedCategoryIndex as number]?.checkpoints[
            selectedCheckPointIndex as number
          ]?.id
        }
        showFooter={selected && selectedCategoryIndex !== undefined}
        showTickAll={false}
        renderItemRight={renderActions}
        title="CHECKPOINTS"
        itemObjects={
          selectedCategoryIndex !== undefined && selected
            ? (selected.categories[selectedCategoryIndex]?.checkpoints ?? []).map((e) => ({
                key: e.id,
                value: e.title,
              }))
            : []
        }
        onItemClick={onCheckpointClick}
        onFooterClick={onAddCheckpoint}
        onChange={(index, value) => {
          if (selected && selectedCategoryIndex !== undefined) {
            setSelected((cl) =>
              cl
                ? {
                    ...cl,
                    categories: cl.categories.map((m, i) => {
                      if (i === selectedCategoryIndex) {
                        return {
                          ...m,
                          checkpoints: m.checkpoints.map((c, j) => {
                            if (j === index) {
                              return {
                                ...c,
                                title: value,
                              }
                            }
                            return c
                          }),
                        }
                      }
                      return m
                    }),
                  }
                : undefined
            )
          }
        }}
      />
    </Col>
  )
}
