import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { PERSIST, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
/** module slice imports */
import { authSlice } from './Modules/auth/authSlice'
import { userSlice } from './Modules/user/userSlice'
import { categorySlice } from './Modules/category/categorySlice'
import { taskSlice } from './Modules/task/taskSlice'
import { companySlice } from './Modules/company/companySlice'
import { checklistSlice } from './Modules/checklist/checklistSlice'
import { itemSlice } from './Modules/item/itemSlice'
import { taskTemplatesSlice } from './Modules/taskTemplates/taskTemplatesSlice'
import { useDispatch } from 'react-redux'

/** your app's top-level reducers */
const appReducer = combineReducers({
	auth: authSlice.reducer,
	user: userSlice.reducer,
	category: categorySlice.reducer,
	task: taskSlice.reducer,
	item: itemSlice.reducer,
	company: companySlice.reducer,
	checklist: checklistSlice.reducer,
	taskTemplates: taskTemplatesSlice.reducer,
})

export type RootState = ReturnType<typeof appReducer>
export const LOGOUT_ACTION = 'auth/logout'

/**
 * rootReducer
 * - with the ability to reset state when logout action is fired
 */
const rootReducer = (state: RootState | undefined, action: { type: string }): RootState => {
	if (action.type === LOGOUT_ACTION) {
		/** for all keys defined in your persistConfig(s) */
		storage.removeItem('persist:root')
		storage.removeItem('chibi-tkn')
		state = undefined
	}

	return appReducer(state, action)
}

/**
 * persistedReducer
 * - with redux-persist config
 */
const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	blacklist: ['task', 'item', 'checklist', 'taskTemplates'],
}

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer)

/**
 * ignore serializable check in redux-persist PERSIST:
 * see https://github.com/rt2zz/redux-persist/issues/988
 */
export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [PERSIST, LOGOUT_ACTION],
			},
		}),
})

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()

export const persistor = persistStore(store)
