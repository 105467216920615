import React from 'react'
import { Col, Form, Input, Typography } from 'antd'
import styled from 'styled-components'
import { colors } from '../../../../Assets/colors'
import { CheckList } from '../../checklistSlice'
import { EditState } from './types'

interface DetailsSectionProps {
  editState: EditState
  setEditState: (state: EditState) => void
  selected?: CheckList
  selectedCategoryIndex?: number
  selectedCheckPointIndex?: number
  onCheckpointTitleChange: (value: string) => void
  onDescriptionChange: (value: string) => void
}

export const DetailsSection: React.FC<DetailsSectionProps> = ({
  editState,
  setEditState,
  selected,
  selectedCategoryIndex,
  selectedCheckPointIndex,
  onCheckpointTitleChange,
  onDescriptionChange,
}) => {
  const getSelectedCheckpoint = () => {
    if (
      selectedCategoryIndex !== undefined &&
      selectedCheckPointIndex !== undefined &&
      selected?.categories[selectedCategoryIndex]?.checkpoints
    ) {
      return selected.categories[selectedCategoryIndex].checkpoints[selectedCheckPointIndex]
    }
    return undefined
  }

  const selectedCheckpoint = getSelectedCheckpoint()

  return (
    <Col span={10}>
      <DetailsHeader>
        {editState.checkpoint ? (
          <Input
            placeholder="Title"
            size="large"
            disabled={selectedCheckPointIndex === undefined}
            name="checkpoint"
            value={selectedCheckpoint?.title ?? ''}
            onBlur={() => setEditState({ ...editState, checkpoint: !editState.checkpoint })}
            onChange={(e) => onCheckpointTitleChange(e.target.value)}
          />
        ) : (
          <div onClick={() => setEditState({ ...editState, checkpoint: !editState.checkpoint })}>
            <Typography.Title
              level={4}
              ellipsis={{
                rows: 1,
              }}>
              {selectedCheckpoint?.title ?? ''}
            </Typography.Title>
          </div>
        )}
      </DetailsHeader>
      <DetailsContainer>
        <Form layout="vertical">
          <Form.Item label="DESCRIPTION">
            <Input.TextArea
              value={selectedCheckpoint?.description ?? ''}
              onChange={(e) => onDescriptionChange(e.target.value)}
            />
          </Form.Item>
        </Form>
      </DetailsContainer>
    </Col>
  )
}

const DetailsHeader = styled.div`
  border: 1px solid ${colors.borderColor};
  padding-left: 18px;
  padding-right: 18px;
  height: 42px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 8px;
`

const DetailsContainer = styled.div`
  border: 1px solid ${colors.borderColor};
  padding: 10px;
  font-weight: bold;
`
