import React from 'react'
import { Col } from 'antd'
import { CheckbeeCascader } from '../../../../Components/CheckbeeCascader'
import { CheckList } from '../../checklistSlice'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons/lib'
import CloneIcon from '../../../../Assets/SvgIcons/CloneIcon'

interface AreasSectionProps {
  selected?: CheckList
  selectedCategoryIndex?: number
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void
  onAreaClick: (value: { index: number }) => void
  onAddArea: () => void
  onDeleteArea: (index: number) => void
  onCloneArea: (index: number) => void
  setSelected: React.Dispatch<React.SetStateAction<CheckList | undefined>>
}

export const AreasSection: React.FC<AreasSectionProps> = ({
  selected,
  selectedCategoryIndex,
  onSortEnd,
  onAreaClick,
  onAddArea,
  onDeleteArea,
  onCloneArea,
  setSelected,
}) => {
  const renderActions = ({ index }: { index: number }) => {
    return (
      <div>
        <Button
          style={{ padding: 0, marginRight: 10 }}
          type="text"
          onClick={(e) => {
            e.stopPropagation()
            onDeleteArea(index)
          }}>
          <DeleteOutlined style={{ fontSize: 18 }} />
        </Button>
        <Button
          style={{ padding: 0 }}
          type="text"
          onClick={(e) => {
            e.stopPropagation()
            onCloneArea(index)
          }}>
          <CloneIcon />
        </Button>
      </div>
    )
  }

  return (
    <Col span={7}>
      <CheckbeeCascader
        onSortEnd={onSortEnd}
        selectedItem={selected?.categories?.[selectedCategoryIndex as number]?.id}
        showFooter
        showTickAll={false}
        title="AREAS"
        allowInlineEditing={true}
        renderItemRight={renderActions}
        onFooterClick={onAddArea}
        itemObjects={
          selected
            ? selected?.categories?.map((m) => ({
                key: m.id as string,
                value: m.name,
              }))
            : []
        }
        onItemClick={onAreaClick}
        onChange={(index, value) => {
          if (selected) {
            setSelected((checklist) =>
              checklist
                ? {
                    ...checklist,
                    categories: checklist.categories.map((c, i) => {
                      if (index === i) {
                        return {
                          ...c,
                          name: value,
                        }
                      }
                      return c
                    }),
                  }
                : undefined
            )
          }
        }}
      />
    </Col>
  )
}
