/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Col, Form, Input, Row, Radio, Button, Select, Tooltip, DatePicker, Upload, Typography } from 'antd'
import {
	ExclamationOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
	MessageOutlined,
	UserOutlined,
	ApartmentOutlined,
	PlusOutlined,
} from '@ant-design/icons/lib'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { colors } from '../../../Assets/colors'
import moment from 'moment'
import DateIcon from '../../../Assets/SvgIcons/DateIcon'
import { uploadImage } from '../actions/uploadImage'
import { Task } from '../taskSlice'
import { Category } from '../../category/categorySlice'
import { User } from '../../auth/authSlice'
import { VisibleItem } from '../../../Components/VisibleItem'
import CommentsSectionContainer from '../containers/CommentsSectionContainer'
import { Item } from '../../item/itemSlice'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'

interface Props {
	isEditingTask: boolean
	isGettingCategories: boolean
	isGettingUsers: boolean
	isEditTaskFulfilled: boolean
	isGettingTasks: boolean
	editTask: (values: TaskForm) => void
	deleteTask: (id: string) => void
	isDeletingTask: boolean
	task: Task
	categories: Category[]
	availableAssignees: User[]
}

const StyledForm = styled(Form)`
	margin-top: 10px;
	.ant-form-item-label > label {
		height: 38px;
	}
`

export type Status = 'COMPLETED' | 'CREATED' | 'INPROGRESS'

export type TaskForm = {
	title?: string
	description?: string
	priority?: number
	category?: string // id
	subCategory?: string // id
	assign?: string // id
	dueDate?: string | null
	status?: Status
	item?: string
	imageUrls?: string[]
}

const TaskFormSchema = Yup.object().shape({
	title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	description: Yup.string().nullable(),
	priority: Yup.number().required('Required'),
	category: Yup.string().required('Required'),
	subCategory: Yup.string().required('Required'),
	dueDate: Yup.string().nullable(),
	status: Yup.string().oneOf(['CREATED', 'INPROGRESS', 'COMPLETED']),
})

type TaskFields =
	| 'title'
	| 'description'
	| 'priority'
	| 'category'
	| 'categoryId'
	| 'subCategory'
	| 'subCategoryId'
	| 'dueDate'
	| 'status'
	| 'itemId'
	| 'assign'

export const EditTask: React.FC<Props> = ({
	task,
	availableAssignees,
	isGettingUsers,
	isEditingTask,
	isGettingCategories,
	categories,
	editTask,
	deleteTask,
	isDeletingTask,
}) => {
	const getCategoryIndex = (subCatId: string) =>
		categories.findIndex((cat) => cat.subCategories.some((subCat) => subCat.id === subCatId))
	const items: Item[] = useSelector<RootState, Item[]>((state) => state.item.list)
	const itemsEnabled = useSelector<RootState, boolean>((state) => state.auth.user.company.itemsEnabled)

	const [selectedCatIndex, setSelectedCatIndex] = React.useState(getCategoryIndex(task.subCategory.id))
	const [imageUploading, setImageUploading] = React.useState(false)

	const [images, setImages] = React.useState<any[]>(
		task.imageUrls?.map((e, i) => ({ uid: i, url: e, status: 'done' })) ?? []
	)

	const initialValues: TaskForm = {
		title: task.title,
		description: task.description,
		priority: task.priority,
		subCategory: task.subCategoryId ?? '',
		category: task.categoryId ?? '',
		assign: task.assign?.id ?? '',
		dueDate: task.dueDate ?? null,
		status: task.status,
		item: task.item?.id ?? '',
	}

	const [form] = Form.useForm()
	const formState = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: TaskFormSchema,
		onSubmit: (values) => {
			let requestData = {
				id: task.id,
				imageUrls: images.map((image) => image.url),
			}
			const addIfEdited = (fieldName: TaskFields, newValue: string | number | undefined | null) =>
				task[fieldName] !== newValue ? { [fieldName]: newValue } : {}

			requestData = { ...requestData, ...addIfEdited('title', values.title) }
			requestData = { ...requestData, ...addIfEdited('description', values.description) }
			requestData = { ...requestData, ...addIfEdited('priority', values.priority) }
			requestData = { ...requestData, ...addIfEdited('category', values.category) }
			requestData = { ...requestData, ...addIfEdited('subCategory', values.subCategory) }
			requestData = { ...requestData, ...addIfEdited('assign', values.assign ? values.assign : null) }
			requestData = { ...requestData, ...addIfEdited('dueDate', values.dueDate) }
			requestData = { ...requestData, ...addIfEdited('status', values.status) }
			requestData = { ...requestData, ...addIfEdited('itemId', values.item ? values.item : null) }

			editTask(requestData)
		},
	})

	const disabled = !!task.archived
	return (
		<Row style={{ pointerEvents: disabled ? 'none' : 'unset' }} gutter={32}>
			{/* TASK FORM */}
			<Col span={11}>
				<VisibleItem visible={disabled}>
					<Typography.Text style={{ marginLeft: 20 }} type={'danger'}>
						Task is archived, You can't change it anymore
					</Typography.Text>
				</VisibleItem>
				<StyledForm form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 20 }}>
					{/* title */}
					<Form.Item
						label={<FileTextOutlined style={styles.iconLabel} />}
						name='title'
						validateStatus={formState.touched.title && formState.errors.title ? 'error' : ''}>
						<Input
							size='large'
							placeholder='TITLE'
							name='title'
							defaultValue={formState.initialValues.title}
							value={formState.values.title}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						label={<MessageOutlined style={styles.iconLabel} />}
						name='description'
						validateStatus={formState.touched.description && formState.errors.description ? 'error' : ''}>
						<Input.TextArea
							autoSize={{ minRows: 4, maxRows: 8 }}
							placeholder='DESCRIPTION'
							name='description'
							defaultValue={formState.initialValues.description}
							value={formState.values.description}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>

					{/* category */}
					<Form.Item
						label={<ApartmentOutlined style={styles.iconLabel} />}
						name='category'
						hasFeedback
						validateStatus={formState.errors.category ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='CATEGORY' />
							<Select
								style={{ flex: '1 0 65%' }}
								size='large'
								loading={isGettingCategories}
								disabled={isGettingCategories}
								value={formState.values.category}
								onChange={(value) => {
									formState.setFieldValue('category', value)
									const index = categories.findIndex((cat) => cat.id === value)
									setSelectedCatIndex(index)
									formState.setFieldValue('subCategory', categories[index].subCategories[0].id)
									formState.setFieldValue('assign', '')
									formState.setFieldValue('item', '')
								}}>
								{categories.length &&
									categories
										.filter((e) => e.subCategories.length)
										.map((cat) => {
											return (
												<Select.Option key={cat.id} value={cat.id}>
													{cat.name}
												</Select.Option>
											)
										})}
							</Select>
						</Input.Group>
					</Form.Item>

					{/* subcategory */}
					<Form.Item
						label={<ApartmentOutlined style={styles.iconLabel} />}
						name='category'
						hasFeedback
						validateStatus={formState.errors.category ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='SUBCATEGORY' />
							<Select
								style={{ flex: '1 0 65%' }}
								size='large'
								loading={isGettingCategories}
								disabled={isGettingCategories}
								value={formState.values.subCategory}
								onChange={(value) => {
									formState.setFieldValue('subCategory', value)
									formState.setFieldValue('item', '')
									formState.setFieldValue('assign', '')
								}}>
								{categories.length &&
									categories[selectedCatIndex].subCategories.map((subcat) => {
										return (
											<Select.Option key={subcat.id} value={subcat.id}>
												{subcat.name}
											</Select.Option>
										)
									})}
							</Select>
						</Input.Group>
					</Form.Item>

					{/* item */}
					<VisibleItem visible={itemsEnabled}>
						<Form.Item
							label={<ApartmentOutlined style={styles.iconLabel} />}
							name='item'
							hasFeedback
							validateStatus={formState.errors.item ? 'error' : ''}>
							<Input.Group compact style={{ display: 'flex' }}>
								<Input disabled size='large' defaultValue='ITEM' />
								<Select
									style={{ flex: '1 0 65%' }}
									size='large'
									value={formState.values.item}
									onChange={(value) => {
										formState.setFieldValue('item', value)
									}}>
									<Select.Option key={''} value={''}>
										{'Link an item(Optional)'}
									</Select.Option>
									{items
										.filter((item) => item.subCategoryId === formState.values.subCategory)
										.map((it) => (
											<Select.Option key={it.id} value={it.id}>
												{it.name}
											</Select.Option>
										))}
								</Select>
							</Input.Group>
						</Form.Item>
					</VisibleItem>
					{/* assignee */}
					<Form.Item
						hasFeedback
						label={<UserOutlined style={styles.iconLabel} />}
						validateStatus={formState.errors.assign ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='ASSIGN TO' />
							<Select
								style={{ flex: '1 0 65%' }}
								size='large'
								loading={isGettingUsers}
								disabled={isGettingUsers}
								value={formState.values.assign}
								onChange={(value) => formState.setFieldValue('assign', value)}>
								<Select.Option value={''}>{'No one'}</Select.Option>
								{availableAssignees.length &&
									availableAssignees
										.filter((user) => user.subCategories.find((subCat) => subCat.id === formState.values.subCategory))
										.map((user) => (
											<Select.Option key={user.id} value={user.id}>
												{user.firstName} {user.lastName}
											</Select.Option>
										))}
							</Select>
						</Input.Group>
					</Form.Item>

					{/* due date */}
					<Form.Item hasFeedback label={<DateIcon />} validateStatus={formState.errors.assign ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='DUE DATE' />
							<DatePicker
								disabledDate={(current) => current < moment().startOf('D')}
								style={{ paddingTop: 8, flex: '1 0 65%' }}
								onChange={(value) => formState.setFieldValue('dueDate', moment(value).format('YYYY-MM-DD'))}
								defaultValue={formState.initialValues.dueDate ? moment(formState.initialValues.dueDate) : undefined}
							/>
						</Input.Group>
					</Form.Item>

					{/* status */}
					<Form.Item
						hasFeedback
						label={<ExclamationOutlined style={styles.iconLabel} />}
						validateStatus={formState.errors.status ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='STATUS' />
							<Radio.Group
								size='large'
								name='status'
								buttonStyle='solid'
								defaultValue='CREATED'
								style={{ flex: '1 0 65%', display: 'flex' }}
								value={formState.values.status}
								onChange={(e) => formState.setFieldValue('status', e.target.value)}>
								<Radio.Button style={{ flex: 1 }} value={'CREATED'}>
									Created
								</Radio.Button>
								<Radio.Button style={{ flex: 1 }} value={'INPROGRESS'}>
									In Progress
								</Radio.Button>
								<Radio.Button style={{ flex: 1 }} value={'COMPLETED'}>
									Completed
								</Radio.Button>
							</Radio.Group>
						</Input.Group>
					</Form.Item>

					{/* priority */}
					<Form.Item
						hasFeedback
						label={<ExclamationOutlined style={styles.iconLabel} />}
						validateStatus={formState.errors.priority ? 'error' : ''}>
						<Input.Group compact style={{ display: 'flex' }}>
							<Input disabled size='large' defaultValue='PRIORITY' />
							<Radio.Group
								size='large'
								name='priority'
								buttonStyle='solid'
								defaultValue='medium'
								style={{ marginRight: 12, flex: '1 0 60%' }}
								value={formState.values.priority}
								onChange={(e) => formState.setFieldValue('priority', e.target.value)}>
								<Radio.Button style={styles.radioButton} value={1}>
									!
								</Radio.Button>
								<Radio.Button style={styles.radioButton} value={2}>
									!!
								</Radio.Button>
								<Radio.Button style={styles.radioButton} value={3}>
									!!!
								</Radio.Button>
							</Radio.Group>
							<Tooltip title='Set the priority of this task' placement='right'>
								<InfoCircleOutlined />
							</Tooltip>
						</Input.Group>
					</Form.Item>
				</StyledForm>
			</Col>
			<Col span={6}>
				<Upload
					onRemove={(file) => {
						setImages(images.filter((image) => image.uid !== file.uid))
					}}
					customRequest={async ({ file }) => {
						setImageUploading(true)
						const response = await uploadImage(file as File)
						setImages([...images, { uid: new Date().getTime().toString(), url: response.data, status: 'done' }])
						setImageUploading(false)
					}}
					listType='picture-card'
					fileList={images}>
					{images.length >= 4 ? null : (
						<div>
							<PlusOutlined />
							<div style={{ marginTop: 8 }}>Upload</div>
						</div>
					)}
				</Upload>
			</Col>
			<Col span={7}>
				<CommentsSectionContainer taskId={task.id} />
			</Col>

			<VisibleItem visible={!disabled}>
				<div className='task-edit-actions-container'>
					<Button
						className='task-action-button'
						type='primary'
						style={{ marginRight: 20 }}
						disabled={isEditingTask || imageUploading}
						loading={isEditingTask}
						onClick={() => formState.handleSubmit()}>
						SAVE TASK
					</Button>

					<Button
						className='task-action-button'
						type='primary'
						danger
						disabled={isDeletingTask || imageUploading}
						loading={isDeletingTask}
						onClick={() => deleteTask(task.id)}>
						ARCHIVE
					</Button>
				</div>
			</VisibleItem>
		</Row>
	)
}

const styles = {
	iconLabel: { fontSize: 24, color: colors.primaryColor },
	radioButton: { textAlign: 'center' as const },
}
