import { Typography } from 'antd'
import React from 'react'
import { Row, Col } from 'antd'
import './styles.css'
import { Cell } from './TaskTemplatesTableStructure'
const HeadCell = ({ name, icon }: Cell) => {
	return (
		<div className='tasks-header-cell'>
			<Typography className='tasks-header-text'>{name}</Typography>
			{icon && icon()}
		</div>
	)
}

export const TasksTemplatesTableHeader = ({ cells }: { cells: Cell[] }) => {
	return (
		<Row className='table-header'>
			{cells.map((e) => (
				<Col key={e.id} span={e.span}>
					<HeadCell id={e.id} name={e.name} icon={e.icon} />
				</Col>
			))}
		</Row>
	)
}
