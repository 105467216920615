import React from 'react'
import { Menu, Spin, Typography } from 'antd'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { MenuProps } from 'antd/es/menu'
import styled from 'styled-components'

import { colors } from '../Assets/colors'
import { useWindowSize } from '../Hooks/useWindowSize'

interface Props extends MenuProps {
	items: Array<{ key: string; content: string }>
	title: string
	loading?: boolean
	onFooterClick?: () => void
	rightIcon?: Function
	renderRow?: Function
}

const StyledMenu = styled(Menu)`
	border: 1px solid ${colors.borderColor};
	border-inline-end: 1px solid ${colors.borderColor} !important;

	.ant-menu-item {
		margin: 0px;
		padding: 0px;
		padding-left: 20px !important;
	}
	.ant-menu-item:not(:last-child) {
		border-bottom: 1px solid ${colors.borderColor};
	}
	.ant-menu-item-active {
		border-right: 1px solid ${colors.borderColor};
	}
	.ant-menu-item-selected {
		background-color: ${colors.selectedItem};
	}
	.category-item-row {
		height: 42px;
		border-radius: 0px;
		width: 100%;
		span {
			text-align: left;
		}
	}
`

export const CheckbeeMenu: React.FC<Props> = ({
	title,
	items = [],
	loading,
	onFooterClick,
	rightIcon,
	renderRow,
	...menuProps
}) => {
	const { height } = useWindowSize()
	const menuHeight = height ? height - 360 : 500

	return (
		<div style={styles.menuContainer}>
			<div style={styles.menuHeader}>
				<Typography.Title level={4} style={{ margin: 0 }}>
					{title}
				</Typography.Title>
				{loading && <Spin style={{ marginLeft: 8 }} indicator={<LoadingOutlined spin />} />}
			</div>
			<div style={{ maxHeight: menuHeight, overflowY: 'auto' }}>
				<StyledMenu mode='inline' {...menuProps}>
					{items.length &&
						items.map((item) => (
							<>
								<Menu.Item key={item.key} className={'category-item-row'}>
									{!!renderRow ? (
										renderRow?.(item)
									) : (
										<>
											<Typography.Text strong>{item.content}</Typography.Text>
											{rightIcon ? rightIcon(item.key) : null}
										</>
									)}
								</Menu.Item>
							</>
						))}
				</StyledMenu>
			</div>
			<div style={styles.menuFooter} onClick={onFooterClick}>
				<PlusOutlined style={styles.menuFooterIcon} />
			</div>
		</div>
	)
}

const styles = {
	menuContainer: {
		width: 256,
	},
	menuHeader: {
		padding: '0 20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 42,
		border: `1px solid ${colors.borderColor}`,
		borderBottom: 'none',
		borderRadius: '5px 5px 0 0',
		background: colors.greyShade,
	},
	menuFooter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 42,
		border: `1px solid ${colors.borderColor}`,
		borderTop: 'none',
		background: colors.greyShade,
		cursor: 'pointer',
	},
	menuFooterIcon: { fontWeight: 'bold' as const, fontSize: '20px', color: colors.primaryColor },
}
