/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Col, Form, Input, Row, Button, Select, Modal, InputNumber } from 'antd'
import { FileTextOutlined, MessageOutlined, UserOutlined, ApartmentOutlined } from '@ant-design/icons/lib'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { colors } from '../../../Assets/colors'
import PlusIcon from '../../../Assets/SvgIcons/PlusIcon'
import { User } from '../../auth/authSlice'
import { Category, SubCategory } from '../../category/categorySlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface Props {
	isCreatingItem: boolean
	isGettingCategories: boolean
	isGettingUsers: boolean
	isCreateItemFulfilled: boolean
	isGettingItems: boolean
	createItem: (values: any) => void
	availableAssignees: User[]
	categories: Category[]
}

const StyledForm = styled(Form)`
	.ant-form-item-label > label {
		height: 38px;
	}
`

export type ItemForm = {
	name: string
	type: string
	manufacturer: string
	prodYear: number
	serialNumber: string
	description: string
	subCategory: string // id
	assign?: string // id
}

const ItemFormSchema = Yup.object().shape({
	name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	description: Yup.string().min(2, 'Too Short!').optional(),
	priority: Yup.number().optional(),
	category: Yup.string().optional(),
	subCategory: Yup.string(),
})

export const AddItem: React.FC<Props> = ({
	availableAssignees,
	isGettingUsers,
	isCreatingItem,
	isGettingCategories,
	isCreateItemFulfilled,
	createItem,
}) => {
	const [modalVisible, setModalVisible] = React.useState(false)
	const subCategories = useSelector<RootState, SubCategory[]>((state) =>
		state.category.list.reduce((result: SubCategory[], row: Category) => [...result, ...row.subCategories], [])
	)

	const initialValues: ItemForm = {
		name: '',
		description: '',
		type: '',
		manufacturer: '',
		prodYear: new Date().getFullYear(),
		serialNumber: '',
		subCategory: subCategories?.[0]?.id ?? '',
		assign: '',
	}

	const [form] = Form.useForm()
	const formState = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: ItemFormSchema,
		onSubmit: (values) => {
			let requestData: { [key: string]: string | number } = {
				name: values.name,
				subCategoryId: values.subCategory,
			}

			requestData = values.description ? { ...requestData, description: values.description } : requestData
			requestData = values.type ? { ...requestData, type: values.type } : requestData
			requestData = values.prodYear ? { ...requestData, prodYear: `${values.prodYear}` } : requestData
			requestData = values.manufacturer ? { ...requestData, manufacturer: values.manufacturer } : requestData
			requestData = values.assign ? { ...requestData, assignId: values.assign } : requestData
			requestData = values.serialNumber ? { ...requestData, serialNumber: values.serialNumber } : requestData

			createItem(requestData)
		},
	})

	// effect to reset form after request is fulfilled
	React.useEffect(() => {
		if (isCreateItemFulfilled) {
			// reset formik and antd forms
			formState.resetForm()
			form.resetFields()
			setModalVisible(false)
		}
	}, [isCreateItemFulfilled])

	return (
		<div>
			<Button
				onClick={() => setModalVisible(true)}
				style={{ marginTop: -5 }}
				size='large'
				icon={<PlusIcon />}
				type='text'
			/>
			<Modal width={500} title='Add Item' onCancel={() => setModalVisible(false)} footer={null} open={modalVisible}>
				<Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
					<Col span={23}>
						<StyledForm form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
							{/* title */}
							{['name' as const, 'type' as const, 'manufacturer' as const, 'serialNumber' as const].map((field) => (
								<Form.Item
									label={<FileTextOutlined style={styles.iconLabel} />}
									name={field}
									validateStatus={formState.touched[field] && formState.errors[field] ? 'error' : ''}>
									<Input
										size='large'
										placeholder={field.toLocaleUpperCase()}
										name={field}
										value={formState.values[field]}
										onChange={formState.handleChange}
										onBlur={formState.handleBlur}
									/>
								</Form.Item>
							))}
							<Form.Item
								label={<FileTextOutlined style={styles.iconLabel} />}
								name={'prodYear'}
								validateStatus={formState.touched.prodYear && formState.errors.prodYear ? 'error' : ''}>
								<InputNumber
									style={{ width: '100%' }}
									size='large'
									placeholder={'PRODUCTION YEAR'}
									name='prodYear'
									min={1900}
									defaultValue={formState.values.prodYear}
									max={new Date().getFullYear() as number}
									value={formState.values.prodYear}
									onChange={(value) => formState.setFieldValue('prodYear', value)}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>

							<Form.Item
								label={<MessageOutlined style={styles.iconLabel} />}
								name='description'
								validateStatus={formState.touched.description && formState.errors.description ? 'error' : ''}>
								<Input.TextArea
									autoSize={{ minRows: 4, maxRows: 8 }}
									placeholder='DESCRIPTION'
									name='description'
									value={formState.values.description}
									onChange={formState.handleChange}
									onBlur={formState.handleBlur}
								/>
							</Form.Item>

							{/* subcategory */}
							<Form.Item
								label={<ApartmentOutlined style={styles.iconLabel} />}
								name='category'
								hasFeedback
								validateStatus={formState.errors.subCategory ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='SUBCATEGORY' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingCategories}
										disabled={isGettingCategories}
										value={formState.values.subCategory}
										onChange={(value) => {
											formState.setFieldValue('subCategory', value)
											formState.setFieldValue('assign', '')
										}}>
										{subCategories.map((subcat) => {
											return (
												<Select.Option key={subcat.id} value={subcat.id}>
													{subcat.name}
												</Select.Option>
											)
										})}
									</Select>
								</Input.Group>
							</Form.Item>

							{/* assignee */}
							<Form.Item
								hasFeedback
								label={<UserOutlined style={styles.iconLabel} />}
								validateStatus={formState.errors.assign ? 'error' : ''}>
								<Input.Group compact style={{ display: 'flex' }}>
									<Input disabled size='large' defaultValue='ASSIGN TO' />
									<Select
										style={{ flex: '1 0 65%' }}
										size='large'
										loading={isGettingUsers}
										disabled={isGettingUsers}
										value={formState.values.assign}
										onChange={(value) => formState.setFieldValue('assign', value)}>
										<Select.Option value={''}>{'No one'}</Select.Option>
										{availableAssignees.length &&
											availableAssignees
												.filter((user) =>
													user.subCategories.find((subCat) => subCat.id === formState.values.subCategory)
												)
												.map((user) => (
													<Select.Option key={user.id} value={user.id}>
														{user.firstName} {user.lastName}
													</Select.Option>
												))}
									</Select>
								</Input.Group>
							</Form.Item>
						</StyledForm>
					</Col>
					<div className='item-edit-actions-container'>
						<Button
							className='item-action-button'
							type='primary'
							danger
							style={{ marginRight: 20 }}
							disabled={isCreatingItem}
							loading={isCreatingItem}
							onClick={() => {
								formState.resetForm()
								form.resetFields()
								setModalVisible(false)
							}}>
							CANCEL
						</Button>
						<Button
							className='item-action-button'
							type='primary'
							disabled={isCreatingItem}
							loading={isCreatingItem}
							onClick={() => formState.handleSubmit()}>
							SAVE ITEM
						</Button>
					</div>
				</Row>
			</Modal>
		</div>
	)
}

const styles = {
	iconLabel: { fontSize: 24, color: colors.primaryColor },
	radioButton: { textAlign: 'center' as const },
}
