import { DeferFn, PromiseFn } from 'react-async'
import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * fetchCheckListDetail action
 * [generic] - can be reused in both defer and promise functions
 */
export const fetchCheckListDetail = async (checklistId: string, signal: AbortSignal) => {
  const token = localStorage.getItem('chibi-tkn')
  const tenantKey = process.env.REACT_APP_TENANT_KEY
  const response = await fetch(`${checkbeeAPIBase}/checklist/${checklistId}`, {
    signal,
    method: 'GET',
    headers: {
      'Tenant-Key': `${tenantKey}`,
      'Authorization': `Bearer ${token}`,
    },
  })

  const result = await response.json()

  if (response.ok && result.status === 'success') {
    return result.data
  } else {
    const err = handleError(result, 'Failed to get checklist details!')
    throw new Error(err)
  }
}

/**
 * fetchCheckListDetailPromiseFn
 * [react-async's promiseFn - automatic invocation]
 */
export const fetchCheckListDetailPromiseFn: PromiseFn<any> = async ({ checklistId }, { signal }) => {
  if (!checklistId) {
    return Promise.reject()
  }
  return await fetchCheckListDetail(checklistId, signal)
}

/**
 * fetchCheckListDetailDeferFn
 * [react-async's deferFn - manually invoked]
 */
export const fetchCheckListDetailDeferFn: DeferFn<any> = async ([checklistId], props, { signal }) => {
  if (!checklistId) {
    return Promise.reject()
  }
  return await fetchCheckListDetail(checklistId, signal)
}
