import axios from 'axios'
import { checkbeeAPIBase } from '../../../Common/api-client.config'

function getBase64(file: File): Promise<string | null | ArrayBuffer> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

/*
 * @param data
 * @param signal
 */
export const uploadImage = async (file: File) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')

	const imageBase64 = await getBase64(file)
	const image = {
		name: file.name,
		baseString: typeof imageBase64 === 'string' ? imageBase64?.split(';base64,')[1] : '',
	}
	const result = await axios.post(`${checkbeeAPIBase}/task/upload/image`, image, {
		headers: {
			'Content-Type': 'application/json',
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
		},
	})
	if (result.status === 200) {
		return result.data
	} else {
		return null
	}
}
