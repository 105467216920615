import { Typography } from 'antd'
import React, { useState } from 'react'
import { Row, Col } from 'antd'
import './styles.css'
import { Cell } from './ItemsTableStructure'
import { Item } from '../itemSlice'
import { EditItemContainer } from '../containers/EditItemContainer'
import { useAsync } from 'react-async'
import { notifyItemAssigneeDeferFn } from '../actions/notifyItemAssignee'
import { useSideEffect } from '../../../Hooks/useSideEffect'

interface CellProps {
	cell: Cell
	item: Item
	informAssignee: Function
	informLoading: boolean
	editItem: Function
	editing: boolean
}

const RowCell = ({ cell, item, informAssignee, informLoading, editItem, editing }: CellProps) => {
	if (cell.id === 'action1') {
		return (
			<div>
				<Typography className='items-header-text'>
					{cell?.getValue ? cell.getValue(informAssignee, informLoading, editItem, editing, item) : null}
				</Typography>
			</div>
		)
	}
	return (
		<div>
			<Typography className='items-header-text'>
				{cell.fieldKey ? item[cell.fieldKey] : cell?.getValue ? cell.getValue(item) : ''}
			</Typography>
		</div>
	)
}

interface ItemTableRow {
	cells: Cell[]
	even: boolean
	item: Item
}

export const ItemsTableRow = ({ cells, even, item }: ItemTableRow) => {
	const [editing, setEditing] = useState(false)

	// notify item assignees
	const notifyItemAssigneeAsync = useAsync({ deferFn: notifyItemAssigneeDeferFn })
	const initiateNotifyItemAssignee = (id: string) => {
		notifyItemAssigneeAsync.run(id)
	}
	useSideEffect({
		asyncFn: notifyItemAssigneeAsync,
		message: 'Notifications is on the way!',
	})

	return (
		<Row className='item-table-row' style={{ backgroundColor: !even ? '#F1F4F5' : 'white' }}>
			{cells.map((e) => (
				<Col key={`${item.id}-${e.id}`} span={e.span}>
					<RowCell
						editing={editing}
						editItem={() => setEditing(!editing)}
						informAssignee={() => initiateNotifyItemAssignee(item.id)}
						item={item}
						cell={e}
						informLoading={notifyItemAssigneeAsync.isLoading}
					/>
				</Col>
			))}
			{editing ? (
				<Col style={{ marginTop: 20 }} span={24}>
					<EditItemContainer item={item} />
				</Col>
			) : null}
		</Row>
	)
}
