import { checkbeeAPIBase } from '../../../Common/api-client.config'
import { handleError } from '../../../Utils/handle-error'

/**
 * fetchTasksPromiseFn
 * [react-async's promiseFn - automatically invoked]
 *
 * @example [usage]
 * const fetchTasksAsync = useAsync({
 * 		promiseFn: fetchTasksPromiseFn,
 * 		id: SOME_ID_HERE, // arguments
 * })
 * useSideEffect({
 * 		asyncFn: fetchTasksAsync,
 * 		message: 'Successful',
 * 		onSuccess: () => {},
 * })
 *
 * @param query
 * @param signal
 */
export const fetchTasksPromiseFn = async (body: any) => {
	const tenantKey = process.env.REACT_APP_TENANT_KEY
	const token = localStorage.getItem('chibi-tkn')
	const response = await fetch(`${checkbeeAPIBase}/task/admin`, {
		method: 'POST',
		headers: {
			'Tenant-Key': `${tenantKey}`,
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	})

	const result = await response.json()

	if (result.status === 'success') {
		return result.data
	} else {
		const err = handleError(result, 'Failed to get task!')
		throw new Error(err)
	}
}
