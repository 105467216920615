import React from 'react'

export default (
	<svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 32.867 31.398'>
		<g id='Group_98' data-name='Group 98' transform='translate(-110.189 -474)'>
			<path
				id='Icon_ionic-ios-square'
				data-name='Icon ionic-ios-square'
				d='M19.73,20.012H7.995a1.208,1.208,0,0,1-1.245-1.16V7.91A1.208,1.208,0,0,1,7.995,6.75H19.73a1.208,1.208,0,0,1,1.245,1.16V18.851A1.208,1.208,0,0,1,19.73,20.012Z'
				transform='translate(122.081 469.25)'
				fill='#fff'
			/>
			<path
				id='Icon_ionic-ios-square-2'
				data-name='Icon ionic-ios-square'
				d='M19.73,20.975H7.995A1.248,1.248,0,0,1,6.75,19.73V7.995A1.248,1.248,0,0,1,7.995,6.75H19.73a1.248,1.248,0,0,1,1.245,1.245V19.73A1.248,1.248,0,0,1,19.73,20.975Z'
				transform='translate(122.081 484.423)'
				fill='#fff'
			/>
			<path
				id='Icon_ionic-ios-square-3'
				data-name='Icon ionic-ios-square'
				d='M19.164,20.975H7.94A1.222,1.222,0,0,1,6.75,19.73V7.995A1.222,1.222,0,0,1,7.94,6.75H19.164a1.222,1.222,0,0,1,1.19,1.245V19.73A1.222,1.222,0,0,1,19.164,20.975Z'
				transform='translate(106.75 484.423)'
				fill='#fff'
			/>
			<path
				id='Icon_ionic-ios-square-4'
				data-name='Icon ionic-ios-square'
				d='M19.73,20.012H7.995a1.208,1.208,0,0,1-1.245-1.16V7.91A1.208,1.208,0,0,1,7.995,6.75H19.73a1.208,1.208,0,0,1,1.245,1.16V18.851A1.208,1.208,0,0,1,19.73,20.012Z'
				transform='translate(104.439 468.25)'
				fill='none'
				stroke='#fff'
				strokeWidth='2'
			/>
		</g>
	</svg>
)
