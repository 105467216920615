import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useAsync } from 'react-async'
import { useNavigate } from 'react-router-dom'

import { RootState } from '../../../store'
import { SubCategoryForm } from '../components/SubCategoryForm'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { createCategoryDeferFn } from '../actions/createCategory'
import { Category, categorySlice, SubCategory } from '../categorySlice'
import { User } from '../../auth/authSlice'
import { createMultipleSubcatsDeferFn } from '../actions/createMultipleSubcats'
import { fetchCategoriesDeferFn } from '../actions/fetchCategories'
import { deleteCategoryDeferFn } from '../actions/deleteCategory'
import { updateSubCategoryDeferFn } from '../actions/updateSubCategory'
import { subCategoryAPI } from '../actions/subCategoryAPI'
import { deleteSubCategoryDeferFn } from '../actions/deleteSubCategory'

const mapState = (state: RootState) => ({
	user: state.auth.user,
})

const mapDispatch = {
	receiveCategories: categorySlice.actions.receiveCategories,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>
export type ConnectedSubCategoryFormProps = PropsFromRedux & {
	isCreatingCategory: boolean
	isFormFulfilled: boolean
	createSubCategory: ({ values, category }: { values: SubCategoryFormState; category: Category }) => void
	deleteSubCategory: (categoryId: string) => void
	updateSubCategory: (values: SubCategoryFormState) => void
}
export type SubCategoryFormContainerProps = PropsFromRedux & {
	newCategory: boolean
	category: Category
	initialFormState?: SubCategoryFormState
	subCategory: SubCategory
}

export interface SubCategoryFormState extends SubCategory {
	users: User[]
}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<SubCategoryFormContainerProps> = (props) => {
	const [formState, setFormState] = React.useState<SubCategoryFormState | undefined>(undefined)
	const navigate = useNavigate()

	// create category block
	const createCategoryAsync = useAsync({ deferFn: createCategoryDeferFn })
	const initiateCreateCategory = async ({ values, category }: { values: SubCategoryFormState; category: Category }) => {
		if (props.newCategory) {
			setFormState(values)
			createCategoryAsync.run({
				name: category.name,
				companyId: props.user.company.id,
			})
		} else {
			await subCategoryAPI.create({ ...values, category: props.category.id })
			initiateFetchCategories(props.user.company.id)
		}
	}
	useSideEffect({
		asyncFn: createCategoryAsync,
		onSuccess: () => {
			const { id: categoryId } = createCategoryAsync.data
			if (formState) {
				const imageUrl = 'https://checkbee-storage.s3.eu-north-1.amazonaws.com/25514AEA-AB82-46BC-86BC-61FFAD8AEEB7.jpg'
				initiateCreateMultipleSubcats([{ ...formState, category: categoryId, imageUrl }])
			}
		},
	})

	const deleteCategoryAsync = useAsync({ deferFn: deleteCategoryDeferFn })
	const handleDeleteCategory = (categoryId: string) => {
		deleteCategoryAsync.run({ categoryId })
	}
	useSideEffect({
		asyncFn: deleteCategoryAsync,
		onSuccess: async () => {
			initiateFetchCategories(props.user.company.id)
		},
	})

	const deleteSubCategoryAsync = useAsync({ deferFn: deleteSubCategoryDeferFn })
	const handleDeleteSubCategory = (subcategoryId: string) => {
		deleteSubCategoryAsync.run({ subcategoryId })
	}
	useSideEffect({
		asyncFn: deleteSubCategoryAsync,
		onSuccess: async () => {
			const { category } = props

			if (category.subCategories.length === 1) {
				handleDeleteCategory(category.id)
			} else {
				initiateFetchCategories(props.user.company.id)
			}
		},
	})

	// create multiple subcats block
	const createMultipleSubcatsAsync = useAsync({ deferFn: createMultipleSubcatsDeferFn })
	const initiateCreateMultipleSubcats = (subcats: any[]) => {
		createMultipleSubcatsAsync.run(subcats)
	}
	useSideEffect({
		asyncFn: createMultipleSubcatsAsync,
		message: 'Successfully created new category!',
		onSuccess: () => {
			initiateFetchCategories(props.user.company.id)
		},
	})

	// fetch categories block
	const fetchCategoriesAsync = useAsync({ deferFn: fetchCategoriesDeferFn })
	const initiateFetchCategories = (companyId: string) => {
		fetchCategoriesAsync.run(companyId)
	}
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
			navigate('/categories')
		},
	})

	const updateSubCategoryAsync = useAsync({ deferFn: updateSubCategoryDeferFn })
	const handleUpdateSubCategory = (values: SubCategoryFormState) => {
		setFormState(values)
		updateSubCategoryAsync.run({
			...values,
			companyId: props.user.company.id,
		})
	}
	useSideEffect({
		asyncFn: updateSubCategoryAsync,
		onSuccess: async () => {
			initiateFetchCategories(props.user.company.id)
		},
	})

	return (
		<SubCategoryForm
			{...props}
			isCreatingCategory={createCategoryAsync.isPending || updateSubCategoryAsync.isPending}
			isFormFulfilled={createCategoryAsync.isFulfilled && updateSubCategoryAsync.isFulfilled}
			createSubCategory={initiateCreateCategory}
			deleteSubCategory={handleDeleteSubCategory}
			updateSubCategory={handleUpdateSubCategory}
		/>
	)
}

export const SubCategoryFormContainer = connector(Wrapper)
