/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Dashboard } from '../components/Dashboard'
import { RootState } from '../../../store'
import { useAsync } from 'react-async'
import { fetchTasksPromiseFn } from '../../task/actions/fetchTasks'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { taskSlice } from '../../task/taskSlice'
import { OverdueTasks, Period, Top5SubCats } from '../../../Types/StatsTypes'
import { getTop5SubcatsDeferFn } from '../actions/statsActions/getTop5Subcats'
import { getOverdueTasksDeferFn } from '../actions/statsActions/getOverdueTasks'
import { getAVGSolvingTimeDeferFn } from '../actions/statsActions/getAVGSolvingTime'
import { getTotalTasksDeferFn } from '../actions/statsActions/getTotalTasks'
import { getTotalTasksTodayDeferFn } from '../actions/statsActions/getTotalTasksToday'
import { getTopWorkersDeferFn } from '../actions/statsActions/getTopWorkers'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	tasks: state.task.list,
})

const mapDispatch = {
	receiveTasks: taskSlice.actions.receiveTasks,
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

export type DashboardContainerProps = PropsFromRedux & {
	isFetchingTasks: boolean

	topSubcats: Top5SubCats[]
	topSubcatsLoading: boolean

	overdueTasks: null | OverdueTasks
	overdueLoading: boolean

	AVGSolvingTimeLoading: boolean
	AVGSolvingTime: any

	totalTasksLoading: boolean
	totalTasks: any

	totalTasksTodayLoading: boolean
	totalTasksToday: any

	topWorkers: any[]
	reloadTopWorkers: (period: Period) => void
	topWorkersLoading: boolean
}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<DashboardContainerProps> = (props) => {
	const [overdueTasks, setOverdueTasks] = useState<OverdueTasks | null>(null)

	// fetch tasks block
	const fetchTasksAsync = useAsync({
		promiseFn: fetchTasksPromiseFn,
	})
	useSideEffect({
		asyncFn: fetchTasksAsync,
		onSuccess: () => {
			const {
				data: { data },
			} = fetchTasksAsync
			// @ts-ignore
			props.receiveTasks({ tasks: data })
		},
	})

	// fetch top 5 subcategories stat
	const fetchTopSubcatsAsync = useAsync({
		deferFn: getTop5SubcatsDeferFn,
	})

	// fetch top 5 subcategories stat
	const fetchOverdueTasksAsync = useAsync({
		deferFn: getOverdueTasksDeferFn,
	})
	useSideEffect({
		asyncFn: fetchOverdueTasksAsync,
		onSuccess: () => {
			const { data } = fetchOverdueTasksAsync
			setOverdueTasks(data)
		},
	})

	// fetch AVG sovling time stat
	const fetchAVGSolvingTimeAsync = useAsync({
		deferFn: getAVGSolvingTimeDeferFn,
	})

	// fetch total tasks stat
	const fetchTotalTasksAsync = useAsync({
		deferFn: getTotalTasksDeferFn,
	})

	// fetch tasks created today stat
	const fetchTasksCreatedTodayAsync = useAsync({
		deferFn: getTotalTasksTodayDeferFn,
	})

	// fetch top 5 workers stat
	const fetchTopWorkersAsync = useAsync({
		deferFn: getTopWorkersDeferFn,
	})

	useEffect(() => {
		fetchTopSubcatsAsync.run()
		fetchOverdueTasksAsync.run()
		fetchAVGSolvingTimeAsync.run()
		fetchTotalTasksAsync.run()
		fetchTasksCreatedTodayAsync.run()
		fetchTopWorkersAsync.run({ period: 'week' })
	}, [])

	return (
		<Dashboard
			{...props}
			isFetchingTasks={fetchTasksAsync.isPending}
			// top 5 subcats
			topSubcats={fetchTopSubcatsAsync.data}
			topSubcatsLoading={fetchTopSubcatsAsync.isLoading}
			// overdue tasks KPIs
			overdueTasks={overdueTasks}
			overdueLoading={fetchOverdueTasksAsync.isPending}
			// AVG Solving time
			AVGSolvingTimeLoading={fetchAVGSolvingTimeAsync.isPending}
			AVGSolvingTime={fetchAVGSolvingTimeAsync.data}
			// Total created tasks compared with created today
			totalTasksLoading={fetchTotalTasksAsync.isPending}
			totalTasks={fetchTotalTasksAsync.data}
			// total created tasks today compared to yesterday
			totalTasksTodayLoading={fetchTasksCreatedTodayAsync.isPending}
			totalTasksToday={fetchTasksCreatedTodayAsync.data}
			// top 5 workers
			topWorkers={fetchTopWorkersAsync.data}
			reloadTopWorkers={(period: Period) => fetchTopWorkersAsync.run({ period })}
			topWorkersLoading={fetchTopWorkersAsync.isLoading}
		/>
	)
}

export const DashboardContainer = connector(Wrapper)
