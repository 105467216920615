import { useAsync } from 'react-async'
import { notification } from 'antd'
import { CheckList } from '../checklistSlice'
import { fetchCheckListDeferFn, fetchCheckListPromiseFn } from '../actions/fetchCheckList'
import { fetchCheckListDetailDeferFn, fetchCheckListDetailPromiseFn } from '../actions/fetchCheckListDetail'
import { createCheckListDeferFn } from '../actions/createCheckList'
import { deleteCheckListDeferFn } from '../actions/deleteCheckList'
import { ChecklistApi } from '../actions/ChecklistApi'
import { useSideEffect } from '../../../Hooks/useSideEffect'

interface UseChecklistAsyncProps {
  onFetchSuccess: (checklists: CheckList[]) => void
  onFetchDetailSuccess: (checklist: CheckList) => void
  onCreateSuccess: () => Promise<void>
  onDeleteSuccess: () => Promise<void>
}

export const useChecklistAsync = ({
  onFetchSuccess,
  onFetchDetailSuccess,
  onCreateSuccess,
  onDeleteSuccess,
}: UseChecklistAsyncProps) => {
  // Fetch checklist list (minimal data)
  const fetchChecklistAsync = useAsync({
    promiseFn: fetchCheckListPromiseFn,
    deferFn: fetchCheckListDeferFn,
  })

  useSideEffect({
    asyncFn: fetchChecklistAsync,
    onSuccess: () => {
      const { data: checklist } = fetchChecklistAsync
      onFetchSuccess(checklist)
    },
  })

  // Fetch single checklist details
  const fetchChecklistDetailAsync = useAsync({
    promiseFn: fetchCheckListDetailPromiseFn,
    deferFn: fetchCheckListDetailDeferFn,
  })

  useSideEffect({
    asyncFn: fetchChecklistDetailAsync,
    onSuccess: () => {
      const { data: checklist } = fetchChecklistDetailAsync
      onFetchDetailSuccess(checklist)
    },
  })

  const createCheckListAsync = useAsync({ deferFn: createCheckListDeferFn })
  useSideEffect({
    asyncFn: createCheckListAsync,
    onSuccess: async () => {
      try {
        await onCreateSuccess()
        notification.success({ message: 'A CheckList was saved successfully.' })
      } catch (e) {
        notification.error({ message: 'Something went wrong!' })
      }
    },
  })

  const deleteCheckListAsync = useAsync({ deferFn: deleteCheckListDeferFn })
  useSideEffect({
    asyncFn: deleteCheckListAsync,
    onSuccess: async () => {
      notification.success({ message: 'A CheckList was deleted successfully.' })
      // After successful deletion, refresh list view and clear detail view
      await onDeleteSuccess()
      await fetchChecklistAsync.run()
    },
  })

  const deleteChecklistCategory = async (categoryId: string) => {
    try {
      await ChecklistApi.deleteCategory(categoryId)
    } catch (error) {
      notification.error({ message: 'Failed to delete category' })
      throw error
    }
  }

  const deleteChecklistCheckpoint = async (checkpointId: string) => {
    try {
      await ChecklistApi.deleteCheckpoint(checkpointId)
    } catch (error) {
      notification.error({ message: 'Failed to delete checkpoint' })
      throw error
    }
  }

  const refreshViews = async (checklistId?: string) => {
    // Refresh list view
    await fetchChecklistAsync.run()
    // If a checklist is selected, refresh its details
    if (checklistId) {
      await fetchChecklistDetailAsync.run(checklistId)
    }
  }

  return {
    fetchChecklistAsync,
    fetchChecklistDetailAsync,
    createCheckListAsync,
    deleteCheckListAsync,
    deleteChecklistCategory,
    deleteChecklistCheckpoint,
    refreshViews,
  }
}
