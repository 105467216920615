import { Typography } from 'antd'
import React from 'react'
import { Row, Col } from 'antd'
import './styles.css'
import { Cell } from './TasksTableStructure'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import OrderByFilter from './FilterComponents/OrderByFilter'
import moment from 'moment'
import { Task } from '../taskSlice'
import ItemFilter from './FilterComponents/ItemsFilter'
const HeadCell = ({ name, icon }: Cell) => {
	return (
		<div className='tasks-header-cell'>
			<Typography className='tasks-header-text'>{name}</Typography>
			{icon && icon()}
		</div>
	)
}

export const TasksTableHeader = ({ cells }: { cells: Cell[] }) => {
	const itemsEnabled = useSelector<RootState, boolean>((state) => state.auth.user.company.itemsEnabled)

	cells[7] = itemsEnabled
		? {
				id: 'ITEM',
				name: 'ITEM',
				icon: () => <ItemFilter />,
				span: 2,
				getValue: (task: Task) => task.item?.name,
		  }
		: {
				id: 'CREATED',
				name: 'CREATED',
				icon: () => <OrderByFilter field={'createdAt'} />,
				span: 2,
				getValue: (task: Task) => moment(task.createdAt).format('DD.MM.YYYY'),
		  }

	return (
		<Row className='table-header'>
			{cells.map((e) => (
				<Col key={e.id} span={e.span}>
					<HeadCell id={e.id} name={e.name} icon={e.icon} />
				</Col>
			))}
		</Row>
	)
}
