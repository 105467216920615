import React from 'react'
import { Button, Form, Input, Row } from 'antd'
import { Link } from 'react-router-dom'
import { UserOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons'

import { SignupContainerProps } from '../containers/SignupContainer'
import { images } from '../../../Assets/images'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './styles.css'
import LocationIcon from '../../../Assets/SvgIcons/LocationIcon'

interface Props extends SignupContainerProps {}

const FormSchema = Yup.object().shape({
	firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	emailAddress: Yup.string().email('Invalid email').required('Required'),
	orgName: Yup.string().min(3, 'Too short to be a phone number!').max(25, 'Too long!').required('Required'),
	orgPhoneNumber: Yup.string().min(5, 'Too short to be a phone number!').max(15, 'Too long!').required('Required'),
	orgEmail: Yup.string().email('Invalid email').required('Required'),
	orgNumber: Yup.string().min(5, 'Too short to be a phone number!').max(10, 'Too long!').required('Required'),
	orgAddress: Yup.string().min(5, 'Too short to be a phone number!').max(100, 'Too long!').required('Required'),
})

const initialValues = {
	firstName: '',
	lastName: '',
	emailAddress: '',
	company: '',
	// org fields
	orgName: '',
	orgPhoneNumber: '',
	orgEmail: '',
	orgNumber: '',
	orgAddress: '',
}

const list = [
	'firstName',
	'lastName',
	'emailAddress',
	'company',
	'orgName',
	'orgPhoneNumber',
	'orgEmail',
	'orgNumber',
	'orgAddress',
] as const
type valueType = typeof list[number]
export const Signup: React.FC<Props> = ({ isLoading, createCompanyThenAdmin }) => {
	const formState = useFormik({
		validateOnChange: false,
		validationSchema: FormSchema,
		initialValues: initialValues,
		onSubmit: (values) => {
			createCompanyThenAdmin(values)
		},
	})

	const fieldError = (field: valueType) => {
		return formState.touched[field] && formState.errors[field] ? 'error' : ''
	}

	const fieldHelp = (field: valueType) => {
		return formState.touched[field] ? formState.errors[field] : ''
	}

	return (
		<div style={styles.container}>
			<div style={styles.header}>
				<div style={styles.logoContainer}>
					<img src={images.logo} alt='' style={styles.logo} />
				</div>
			</div>

			<Form layout='vertical'>
				<Row className='signup-section-title' justify='center' gutter={32}>
					<div className='company-section'>Personal info</div>
				</Row>
				<Row className='signup-section-details' justify='center' gutter={32}>
					<Form.Item
						className='sign-up-input'
						label='FIRST NAME'
						validateStatus={fieldError('firstName')}
						help={fieldHelp('firstName')}>
						<Input
							size={'large'}
							prefix={<UserOutlined />}
							placeholder='FIRST NAME'
							name='firstName'
							onBlur={formState.handleBlur}
							value={formState.values.firstName}
							onChange={formState.handleChange}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						label='LAST NAME'
						validateStatus={fieldError('lastName')}
						help={fieldHelp('lastName')}>
						<Input
							size={'large'}
							prefix={<UserOutlined />}
							placeholder='LAST NAME'
							name='lastName'
							value={formState.values.lastName}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						style={{ width: '100%' }}
						label='EMAIL'
						validateStatus={fieldError('emailAddress')}
						help={fieldHelp('emailAddress')}>
						<Input
							name='emailAddress'
							size={'large'}
							prefix={<MailOutlined />}
							type='email'
							placeholder='EMAIL'
							value={formState.values.emailAddress}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
				</Row>

				{/* Company Fields */}
				<Row className='signup-section-title' justify='center' gutter={32}>
					<div className='company-section'>Company details</div>
				</Row>

				<Row className='signup-section-details' justify='center' gutter={32}>
					<Form.Item
						className='sign-up-input'
						label='NAME'
						validateStatus={fieldError('orgName')}
						help={fieldHelp('orgName')}>
						<Input
							size={'large'}
							prefix={<UserOutlined />}
							placeholder='NAME'
							name='orgName'
							value={formState.values.orgName}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						label='PHONE NUMBER'
						validateStatus={fieldError('orgPhoneNumber')}
						help={fieldHelp('orgPhoneNumber')}>
						<Input
							size={'large'}
							prefix={<PhoneOutlined />}
							type='tel'
							placeholder='PHONE NUMBER'
							name='orgPhoneNumber'
							value={formState.values.orgPhoneNumber}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						label='ORGANIZATION NUMBER'
						validateStatus={fieldError('orgNumber')}
						help={fieldHelp('orgNumber')}>
						<Input
							size={'large'}
							prefix={<UserOutlined />}
							placeholder='ORGANIZATION NUMBER'
							name='orgNumber'
							value={formState.values.orgNumber}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input'
						label='EMAIL'
						validateStatus={fieldError('orgEmail')}
						help={fieldHelp('orgEmail')}>
						<Input
							placeholder='EMAIL'
							name='orgEmail'
							size={'large'}
							prefix={<MailOutlined />}
							type='email'
							value={formState.values.orgEmail}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
					<Form.Item
						className='sign-up-input-full'
						label='ADDRESS'
						validateStatus={fieldError('orgAddress')}
						help={fieldHelp('orgAddress')}>
						<Input
							placeholder='ADDRESS'
							name='orgAddress'
							size={'large'}
							prefix={<LocationIcon />}
							value={formState.values.orgAddress}
							onChange={formState.handleChange}
							onBlur={formState.handleBlur}
						/>
					</Form.Item>
				</Row>

				<div style={styles.content}>
					<Form.Item style={styles.buttonContainer}>
						<Button
							block
							size={'large'}
							type='primary'
							disabled={isLoading}
							loading={isLoading}
							onClick={() => formState.handleSubmit()}>
							Join Checkbee!
						</Button>
					</Form.Item>
				</div>
			</Form>

			<div style={styles.footer}>
				<Link to='/login'>
					<div style={styles.linkAsAText}>I have a Checkbee account</div>
				</Link>
				<a href='https://www.checkbee.app/' rel='noopener noreferrer' target='_blank'>
					<i>What is Checkbee?</i>
				</a>
			</div>
		</div>
	)
}

/** @type {{search: React.CSSProperties}} */
const styles = {
	container: {
		width: '100vw',
		flex: 1,
	},
	header: {
		maxWidth: 300,
		margin: '0 auto',
		paddingTop: 120,
		marginBottom: 72,
	},
	logoContainer: {
		overflow: 'hidden',
		textAlign: 'center' as const,
	},
	logo: {
		width: '60%',
	},
	content: {
		maxWidth: 300,
		margin: '0 auto',
	},
	buttonContainer: {
		marginTop: 32,
	},
	footer: {
		width: '100%',
		textAlign: 'center' as const,
		marginBottom: 20,
	},
	linkAsAText: {
		color: 'rgba(0, 0, 0, 0.65)',
		marginBottom: 14,
	},
}
