import React from 'react'
import { Button, Col, Collapse, Row, Typography } from 'antd'
import { LogoutOutlined } from '@ant-design/icons/lib'

import { AdminOverviewContainerProps } from '../containers/AdminOverviewContainer'
import { LayoutWrapper } from '../../../Components/LayoutWrapper'
import { colors } from '../../../Assets/colors'
import { CompanyDetailsContainer } from '../containers/CompanyDetailsContainer'
import { SubscriptionDetailsContainer } from '../containers/SubscriptionDetailsContainer'
import InvoicesHistoryContainer from '../containers/InvoicesHistoryContainer'

interface Props extends AdminOverviewContainerProps {}

export const AdminOverview: React.FC<Props> = ({ user, initiateLogout }) => {
	const panels = [
		{ key: '1', headerTitle: 'Company Details', content: <CompanyDetailsContainer /> },
		{ key: '2', headerTitle: 'Invoices', content: <InvoicesHistoryContainer /> },
	]

	return (
		<LayoutWrapper>
			<Row gutter={16}>
				<Col span={12}>
					<Row justify='space-between' align='middle' style={styles.headerContainer}>
						<Typography.Title level={4} style={styles.headerTitle}>
							<span style={{ color: '#B11212' }}>Currently logged in as:</span>
							<span style={{ marginLeft: 8 }}>
								{user.firstName} {user.lastName}
							</span>
						</Typography.Title>
						<Button size='large' danger icon={<LogoutOutlined />} onClick={() => initiateLogout()}>
							Log out
						</Button>
					</Row>

					<Collapse defaultActiveKey={[panels[0].key]}>
						{panels.map((panel) => {
							return (
								<Collapse.Panel
									key={panel.key}
									header={
										<Typography.Title level={4} style={styles.headerTitle}>
											{panel.headerTitle}
										</Typography.Title>
									}>
									<p>{panel.content}</p>
								</Collapse.Panel>
							)
						})}
					</Collapse>
				</Col>
				<Col span={12}>
					<SubscriptionDetailsContainer />
				</Col>
			</Row>
		</LayoutWrapper>
	)
}

const styles = {
	headerContainer: {
		padding: '12px 16px',
		background: colors.greyShade,
		marginBottom: 40,
	},
	headerTitle: {
		margin: 0,
	},
}
