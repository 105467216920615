/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useAsync } from 'react-async'
import { RootState, useAppDispatch } from '../../../store'
import { AddTaskTemplates, TaskTemplateForm } from '../components/AddTaskTemplates'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { createTaskTemplateDeferFn } from '../actions/createTaskTemplate'
import { fetchCategoriesPromiseFn } from '../../category/actions/fetchCategories'
import { getTaskTemplatesThunk, taskTemplatesSlice } from '../taskTemplatesSlice'
import { categorySlice } from '../../category/categorySlice'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	categories: state.category.list,
	tasks: state.taskTemplates.list,
	availableAssignees: state.taskTemplates.availableAssignees,
})

const mapDispatch = {
	receiveTasks: taskTemplatesSlice.actions.receiveTasks,
	receiveCategories: categorySlice.actions.receiveCategories,
	receiveAvailableAssignees: taskTemplatesSlice.actions.receiveAvailableAssignees,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<PropsFromRedux> = (props) => {
	const dispatch = useAppDispatch()
	// fetch categories block
	const fetchCategoriesAsync = useAsync({
		promiseFn: fetchCategoriesPromiseFn,
		companyId: props.loggedInUser.company.id,
	})
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
		},
	})

	// create task block
	const createTaskTemplateAsync = useAsync({ deferFn: createTaskTemplateDeferFn })
	const initiateCreateTaskTemplate = (values: TaskTemplateForm) => {
		createTaskTemplateAsync.run({ ...values, createdBy: props.loggedInUser.id })
	}
	useSideEffect({
		asyncFn: createTaskTemplateAsync,
		message: 'Added new task template!',
		onSuccess: () => {
			dispatch(getTaskTemplatesThunk())
		},
	})

	return (
		<AddTaskTemplates
			{...props}
			isCreatingTaskTemplate={createTaskTemplateAsync.isPending}
			isGettingCategories={fetchCategoriesAsync.isPending}
			isGettingUsers={false}
			isGettingTasksTemplates={false}
			isCreateTaskTemplateFulfilled={createTaskTemplateAsync.isFulfilled}
			createTaskTemplate={initiateCreateTaskTemplate}
		/>
	)
}

export const AddTaskTemplatesContainer = connector(Wrapper)
