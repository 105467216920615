/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useAsync } from 'react-async'

import { RootState, useAppDispatch } from '../../../store'
import { AddTask, TaskForm } from '../components/AddTask'
import { useSideEffect } from '../../../Hooks/useSideEffect'
import { createTaskDeferFn } from '../actions/createTask'
import { fetchCategoriesPromiseFn } from '../../category/actions/fetchCategories'
import { fetchUsersPromiseFn } from '../../user/actions/fetchUsers'
import { applyFilter, taskSlice } from '../taskSlice'
import { categorySlice } from '../../category/categorySlice'
import { getItemsThunk } from '../../item/itemSlice'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	categories: state.category.list,
	tasks: state.task.list,
	availableAssignees: state.task.availableAssignees,
})

/**
 * defining mapDispatchToProps as an object
 * see https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	receiveTasks: taskSlice.actions.receiveTasks,
	receiveCategories: categorySlice.actions.receiveCategories,
	receiveAvailableAssignees: taskSlice.actions.receiveAvailableAssignees,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<PropsFromRedux> = (props) => {
	const dispatch = useAppDispatch()
	// fetch categories block
	const fetchCategoriesAsync = useAsync({
		promiseFn: fetchCategoriesPromiseFn,
		companyId: props.loggedInUser.company.id,
	})
	useSideEffect({
		asyncFn: fetchCategoriesAsync,
		onSuccess: () => {
			const { data: categories } = fetchCategoriesAsync
			props.receiveCategories({ categories })
		},
	})

	// fetch users block
	const fetchUsersAsync = useAsync({
		promiseFn: fetchUsersPromiseFn,
		query: { company: props.loggedInUser.company.id },
	})
	useSideEffect({
		asyncFn: fetchUsersAsync,
		onSuccess: () => {
			const { data: users } = fetchUsersAsync
			const unArchivedUsers = users.filter((user: any) => !user.archived)
			props.receiveAvailableAssignees({ users: unArchivedUsers })
		},
	})

	// create task block
	const createTaskAsync = useAsync({ deferFn: createTaskDeferFn })
	const initiateCreateTask = (values: TaskForm) => {
		createTaskAsync.run({ ...values, createdBy: props.loggedInUser.id })
	}
	useSideEffect({
		asyncFn: createTaskAsync,
		message: 'Added new task!',
		onSuccess: () => {
			dispatch(applyFilter({ perPage: props.tasks.length + 1, page: 1 }))
		},
	})

	useEffect(() => {
		dispatch(getItemsThunk())
	}, [])

	return (
		<AddTask
			{...props}
			isCreatingTask={createTaskAsync.isPending}
			isGettingCategories={fetchCategoriesAsync.isPending}
			isGettingUsers={fetchUsersAsync.isPending}
			isGettingTasks={false}
			isCreateTaskFulfilled={createTaskAsync.isFulfilled}
			createTask={initiateCreateTask}
		/>
	)
}

export const AddTaskContainer = connector(Wrapper)
