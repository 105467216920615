/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { AddChecklist } from '../components/AddChecklist'
import { RootState, useAppDispatch } from '../../../store'
import { categorySlice } from '../../category/categorySlice'
import { checklistSlice } from '../checklistSlice'
import { taskSlice } from '../../task/taskSlice'
import { getItemsThunk } from '../../item/itemSlice'

const mapState = (state: RootState) => ({
	loggedInUser: state.auth.user,
	availableAssignees: state.task.availableAssignees,
	checklistCategories: state.checklist.checklistCategories,
	categories: state.category.list,
})

/**
 * defining mapDispatchToProps as an object
 * see https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-an-object
 */
const mapDispatch = {
	receiveCheckList: checklistSlice.actions.receiveCheckList,
	receiveAvailableAssignees: taskSlice.actions.receiveAvailableAssignees,
	receiveCheckListCategories: checklistSlice.actions.receiveCheckListCategories,
	receiveCategories: categorySlice.actions.receiveCategories,
}

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>

/** extra type definitions */
export type ChecklistContainerProps = PropsFromRedux & {}

/** higher-order wrapper component for side effects */
const Wrapper: React.FC<ChecklistContainerProps> = (props) => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(getItemsThunk())
	}, [])

	return <AddChecklist {...props} />
}

export const AddChecklistContainer = connector(Wrapper)
